import { SessionType, TrackSessionDocument } from 'apollo/generated/client-operations';
import { FusionAuthRefreshResponse, LogoutOptions, RefreshAccessTokenPayload, RefreshTokenOptions } from 'auth/types';
import { appConfig } from 'config/app';

export const refreshJwt = async ({
  refreshToken,
  accessToken,
}: RefreshTokenOptions): Promise<FusionAuthRefreshResponse> => {
  if (!refreshToken) {
    throw new Error('Refresh token not provided');
  }

  const response = await fetch('/oauth/refresh', {
    method: 'POST',
    mode: 'same-origin',
    cache: 'no-cache',
    credentials: 'omit',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    body: JSON.stringify({ accessToken, refreshToken }),
  });
  const body: RefreshAccessTokenPayload = await response.json();

  if (!body.accessToken) {
    throw new Error('Access token could not be refreshed');
  }

  return {
    accessToken: body.accessToken,
    refreshToken: body.refreshToken,
  };
};

export const trackUserSession = async (sessionType: SessionType, accessToken: string): Promise<void> => {
  await fetch(appConfig.graphQLUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      query: TrackSessionDocument.loc.source.body,
      variables: { sessionType },
    }),
  });
};

export const logout = ({ fusionAuthURL, fusionAuthClientID, fusionAuthTenantID, redirectURL }: LogoutOptions): void => {
  const redirect = encodeURIComponent(redirectURL);
  // eslint-disable-next-line max-len
  window.location.href = `${fusionAuthURL}/oauth2/logout?client_id=${fusionAuthClientID}&tenantId=${fusionAuthTenantID}&post_logout_redirect_uri=${redirect}`;
};
