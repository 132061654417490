import { appConfig } from 'config/app';
import Cookie from 'js-cookie';

export type SetJwtCookieOptions = {
  accessToken: string;
  cookieKey?: string;
  cookieDomain?: string;
};

export const getCookie = (name: string): string | null => {
  const cookie = Cookie.get(name);
  if (cookie == null) return null;
  return cookie;
};

export const clearSessionCookie = (): void => {
  const domain = appConfig.appEnv !== 'local' ? '.useodin.com' : 'localhost';
  const name = `odin_api${appConfig.isProd ? '' : `_${appConfig.appEnv}`}`;
  Cookie.remove(name, { domain });
};

export const getSessionCookie = (): string | null => {
  const cookie = getCookie(`odin_api${appConfig.isProd ? '' : `_${appConfig.appEnv}`}`);
  return cookie;
};

export const setJwtCookie = ({ accessToken, cookieDomain, cookieKey }: SetJwtCookieOptions): void => {
  const domain = cookieDomain ?? appConfig.appEnv === 'local' ? 'localhost' : 'useodin.com';
  const key = cookieKey ?? `odin_api${appConfig.isProd ? '' : `_${appConfig.appEnv}`}`;
  Cookie.set(key, accessToken, { domain });
};
