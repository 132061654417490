import { getFaIcon } from '@odin-labs/components';

import { faGaugeHigh } from '@fortawesome/pro-light-svg-icons/faGaugeHigh';
import { faStethoscope } from '@fortawesome/pro-light-svg-icons/faStethoscope';
import { faIdCard } from '@fortawesome/pro-light-svg-icons/faIdCard';
import { faCctv } from '@fortawesome/pro-light-svg-icons/faCctv';
import { faUserHardHat } from '@fortawesome/pro-light-svg-icons/faUserHardHat';
import { faTable } from '@fortawesome/pro-light-svg-icons/faTable';
import { faChartSimple } from '@fortawesome/pro-light-svg-icons/faChartSimple';
import { faWrench } from '@fortawesome/pro-light-svg-icons/faWrench';
import { faBullhorn } from '@fortawesome/pro-light-svg-icons/faBullhorn';
import { faChartPie } from '@fortawesome/pro-light-svg-icons/faChartPie';
import { faCompass } from '@fortawesome/pro-light-svg-icons/faCompass';
import { faHardHat } from '@fortawesome/pro-light-svg-icons/faHardHat';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers';
import { faClipboardCheck } from '@fortawesome/pro-light-svg-icons/faClipboardCheck';
import { faLandmark } from '@fortawesome/pro-light-svg-icons/faLandmark';
import { faSitemap } from '@fortawesome/pro-light-svg-icons/faSitemap';

import { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight';
import { faArrowToLeft } from '@fortawesome/pro-light-svg-icons/faArrowToLeft';
import { faArrowFromRight } from '@fortawesome/pro-light-svg-icons/faArrowFromRight';
import { faArrowToRight } from '@fortawesome/pro-light-svg-icons/faArrowToRight';
import { faArrowToBottom } from '@fortawesome/pro-light-svg-icons/faArrowToBottom';
import { faBriefcase } from '@fortawesome/pro-light-svg-icons/faBriefcase';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons/faUserPlus';
import { faUserMinus } from '@fortawesome/pro-light-svg-icons/faUserMinus';
import { faCommentAltPlus } from '@fortawesome/pro-light-svg-icons/faCommentAltPlus';
import { faToolbox } from '@fortawesome/pro-light-svg-icons/faToolbox';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faFilePlus } from '@fortawesome/pro-light-svg-icons/faFilePlus';
import { faFileExcel } from '@fortawesome/pro-light-svg-icons/faFileExcel';
import { faFileVideo } from '@fortawesome/pro-light-svg-icons/faFileVideo';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons/faFilePdf';
import { faFileCode } from '@fortawesome/pro-light-svg-icons/faFileCode';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faSend } from '@fortawesome/pro-light-svg-icons/faSend';
import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil';
import { faUserPen } from '@fortawesome/pro-light-svg-icons/faUserPen';
import { faConstruction } from '@fortawesome/pro-light-svg-icons/faConstruction';
import { faRedo } from '@fortawesome/pro-light-svg-icons/faRedo';
import { faPrint } from '@fortawesome/pro-light-svg-icons/faPrint';
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog';
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars';
import { faHistory } from '@fortawesome/pro-light-svg-icons/faHistory';
import { faFileExport } from '@fortawesome/pro-light-svg-icons/faFileExport';
import { faMagnet } from '@fortawesome/pro-light-svg-icons/faMagnet';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faFileAlt } from '@fortawesome/pro-light-svg-icons/faFileAlt';
import { faCommentMedical } from '@fortawesome/pro-light-svg-icons/faCommentMedical';
import { faNewspaper } from '@fortawesome/pro-light-svg-icons/faNewspaper';
import { faFileSignature } from '@fortawesome/pro-light-svg-icons/faFileSignature';
import { faShieldCheck } from '@fortawesome/pro-light-svg-icons/faShieldCheck';
import { faIdBadge } from '@fortawesome/pro-light-svg-icons/faIdBadge';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { faInbox } from '@fortawesome/pro-light-svg-icons/faInbox';
import { faLightbulbOn } from '@fortawesome/pro-light-svg-icons/faLightbulbOn';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { faSignature } from '@fortawesome/pro-light-svg-icons/faSignature';
import { faTools } from '@fortawesome/pro-light-svg-icons/faTools';
import { faLanguage } from '@fortawesome/pro-light-svg-icons/faLanguage';
import { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar';
import { faCalendarDay } from '@fortawesome/pro-light-svg-icons/faCalendarDay';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faUserClock } from '@fortawesome/pro-light-svg-icons/faUserClock';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons/faMapMarkerAlt';
import { faCreditCardFront } from '@fortawesome/pro-light-svg-icons/faCreditCardFront';
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { faForward } from '@fortawesome/pro-light-svg-icons/faForward';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faBusinessTime } from '@fortawesome/pro-light-svg-icons/faBusinessTime';
import { faMobile } from '@fortawesome/pro-light-svg-icons/faMobile';
import { faDoorOpen } from '@fortawesome/pro-light-svg-icons/faDoorOpen';
import { faQrcode } from '@fortawesome/pro-light-svg-icons/faQrcode';
import { faMagic } from '@fortawesome/pro-light-svg-icons/faMagic';
import { faUsersMedical } from '@fortawesome/pro-light-svg-icons/faUsersMedical';
import { faUsersCog } from '@fortawesome/pro-light-svg-icons/faUsersCog';
import { faListUl } from '@fortawesome/pro-light-svg-icons/faListUl';
import { faDatabase } from '@fortawesome/pro-light-svg-icons/faDatabase';
import { faUnlock } from '@fortawesome/pro-light-svg-icons/faUnlock';
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock';
import { faMinusOctagon } from '@fortawesome/pro-light-svg-icons/faMinusOctagon';
import { faDebug } from '@fortawesome/pro-light-svg-icons/faDebug';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit';
import { faSave } from '@fortawesome/pro-light-svg-icons/faSave';
import { faKey } from '@fortawesome/pro-light-svg-icons/faKey';
import { faSync } from '@fortawesome/pro-light-svg-icons/faSync';
import { faUserGear } from '@fortawesome/pro-light-svg-icons/faUserGear';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons/faMagnifyingGlass';
import { faHelmetSafety } from '@fortawesome/pro-light-svg-icons/faHelmetSafety';
import { faPhone } from '@fortawesome/pro-light-svg-icons/faPhone';
import { faUserTimes } from '@fortawesome/pro-light-svg-icons/faUserTimes';
import { faFileInvoiceDollar } from '@fortawesome/pro-light-svg-icons/faFileInvoiceDollar';
import { faCreditCard } from '@fortawesome/pro-light-svg-icons/faCreditCard';
import { faDownload } from '@fortawesome/pro-light-svg-icons/faDownload';
import { faFileChartLine } from '@fortawesome/pro-light-svg-icons/faFileChartLine';
import { faBuildingColumns } from '@fortawesome/pro-light-svg-icons/faBuildingColumns';
import { faHandsHoldingChild } from '@fortawesome/pro-light-svg-icons/faHandsHoldingChild';
import { faHourglassClock } from '@fortawesome/pro-light-svg-icons/faHourglassClock';
import { faTicket } from '@fortawesome/pro-light-svg-icons/faTicket';
import { faMobileSignal } from '@fortawesome/pro-light-svg-icons/faMobileSignal';
import { faGrid2 } from '@fortawesome/pro-light-svg-icons/faGrid2';
import { faLockKeyhole } from '@fortawesome/pro-light-svg-icons/faLockKeyhole';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash';

import { faMoonStars as fasMoonStars } from '@fortawesome/pro-solid-svg-icons/faMoonStars';
import { faBriefcase as fasBriefcase } from '@fortawesome/pro-solid-svg-icons/faBriefcase';

import { faBriefcase as fadBriefcase } from '@fortawesome/pro-duotone-svg-icons/faBriefcase';

import { faCcVisa } from '@fortawesome/free-brands-svg-icons/faCcVisa';
import { faCcMastercard } from '@fortawesome/free-brands-svg-icons/faCcMastercard';
import { faCcAmex } from '@fortawesome/free-brands-svg-icons/faCcAmex';
import { faCcDiscover } from '@fortawesome/free-brands-svg-icons/faCcDiscover';
import { faCcJcb } from '@fortawesome/free-brands-svg-icons/faCcJcb';
import { faCcDinersClub } from '@fortawesome/free-brands-svg-icons/faCcDinersClub';

export {
  faArrowLeft,
  faArrowRight,
  faArrowToLeft,
  faArrowFromRight,
  faArrowToRight,
  faArrowToBottom,
  faPlus,
  faUserPlus,
  faUserMinus,
  faCommentAltPlus,
  faBriefcase,
  faWrench,
  faFilePlus,
  faFileExcel,
  faFileExport,
  faFileVideo,
  faFilePdf,
  faFileCode,
  faEnvelope,
  faSend,
  faTrash,
  faTrashAlt,
  faToolbox,
  faUser,
  faPen,
  faPencil,
  faUserPen,
  faConstruction,
  faBuilding,
  faRedo,
  faPrint,
  faCog,
  faBars,
  faHistory,
  faMagnet,
  faExclamationTriangle,
  faExclamationCircle,
  faInfoCircle,
  faCheck,
  faFileAlt,
  faCommentMedical,
  faNewspaper,
  faFileSignature,
  faSignature,
  faShieldCheck,
  faIdBadge,
  faHardHat,
  faUserHardHat,
  faTable,
  faChartSimple,
  faSearch,
  faInbox,
  faLightbulbOn,
  faTools,
  faLanguage,
  faCalendar,
  faCalendarDay,
  faClock,
  faUserClock,
  faChevronRight,
  faBullhorn,
  faMapMarkerAlt,
  faCreditCardFront,
  faGlobe,
  faForward,
  faLandmark,
  faTimes,
  faBusinessTime,
  faMobile,
  faDoorOpen,
  faQrcode,
  faMagic,
  faUsersMedical,
  faUsersCog,
  faListUl,
  faDatabase,
  faUnlock,
  faLock,
  faIdCard,
  faMinusOctagon,
  faDebug,
  faLink,
  faEdit,
  faSave,
  faKey,
  faSync,
  faUserGear,
  faMagnifyingGlass,
  faHelmetSafety,
  faPhone,
  faUserTimes,
  faFileInvoiceDollar,
  faCreditCard,
  faDownload,
  faFileChartLine,
  faBuildingColumns,
  faHandsHoldingChild,
  faHourglassClock,
  faSitemap,
  faTicket,
  faMobileSignal,
  faGrid2,
  faLockKeyhole,
};

export { fasMoonStars };
export { fadBriefcase };

export const GaugeHighIcon = getFaIcon({ icon: faGaugeHigh });
export const StethoscopeIcon = getFaIcon({ icon: faStethoscope });
export const IdCardIcon = getFaIcon({ icon: faIdCard });
export const CctvIcon = getFaIcon({ icon: faCctv });
export const UserHardHatIcon = getFaIcon({ icon: faUserHardHat });
export const ChartSimpleIcon = getFaIcon({ icon: faChartSimple });
export const DataTableIcon = getFaIcon({ icon: faTable });
export const WrenchIcon = getFaIcon({ icon: faWrench });
export const BullhornIcon = getFaIcon({ icon: faBullhorn });
export const ChartPieIcon = getFaIcon({ icon: faChartPie });
export const CompassIcon = getFaIcon({ icon: faCompass });
export const HardHatIcon = getFaIcon({ icon: faHardHat });
export const BuildingIcon = getFaIcon({ icon: faBuilding });
export const UsersIcon = getFaIcon({ icon: faUsers });
export const ClipboardCheckIcon = getFaIcon({ icon: faClipboardCheck });
export const LandmarkIcon = getFaIcon({ icon: faLandmark });
export const SitemapIcon = getFaIcon({ icon: faSitemap });

export const ArrowLeftIcon = getFaIcon({ icon: faArrowLeft });
export const ArrowRightIcon = getFaIcon({ icon: faArrowRight });
export const ArrowToLeftIcon = getFaIcon({ icon: faArrowToLeft });
export const ArrowFromRightIcon = getFaIcon({ icon: faArrowFromRight });
export const ArrowToRightIcon = getFaIcon({ icon: faArrowToRight });
export const ArrowToBottomIcon = getFaIcon({ icon: faArrowToBottom });
export const PlusIcon = getFaIcon({ icon: faPlus });
export const UserPlusIcon = getFaIcon({ icon: faUserPlus });
export const UserMinusIcon = getFaIcon({ icon: faUserMinus });
export const CommentAltPlusIcon = getFaIcon({ icon: faCommentAltPlus });
export const BriefcaseIcon = getFaIcon({ icon: faBriefcase });
export const FilePlusIcon = getFaIcon({ icon: faFilePlus });
export const FileExcelIcon = getFaIcon({ icon: faFileExcel });
export const FileExportIcon = getFaIcon({ icon: faFileExport });
export const FileVideoIcon = getFaIcon({ icon: faFileVideo });
export const FilePdfIcon = getFaIcon({ icon: faFilePdf });
export const FileCodeIcon = getFaIcon({ icon: faFileCode });
export const EnvelopeIcon = getFaIcon({ icon: faEnvelope });
export const MapMarkerIcon = getFaIcon({ icon: faMapMarkerAlt });
export const SendIcon = getFaIcon({ icon: faSend });
export const TrashIcon = getFaIcon({ icon: faTrash });
export const TrashAltIcon = getFaIcon({ icon: faTrashAlt });
export const ToolboxIcon = getFaIcon({ icon: faToolbox });
export const UserIcon = getFaIcon({ icon: faUser });
export const PenIcon = getFaIcon({ icon: faPen });
export const PencilIcon = getFaIcon({ icon: faPencil });
export const UserPenIcon = getFaIcon({ icon: faUserPen });
export const ConstructionIcon = getFaIcon({ icon: faConstruction });
export const RedoIcon = getFaIcon({ icon: faRedo });
export const PrintIcon = getFaIcon({ icon: faPrint });
export const CogIcon = getFaIcon({ icon: faCog });
export const BarsIcon = getFaIcon({ icon: faBars });
export const HistoryIcon = getFaIcon({ icon: faHistory });
export const MagnetIcon = getFaIcon({ icon: faMagnet });
export const ExclamationTriangleIcon = getFaIcon({ icon: faExclamationTriangle });
export const ExclamationCircleIcon = getFaIcon({ icon: faExclamationCircle });
export const InfoCircleIcon = getFaIcon({ icon: faInfoCircle });
export const CheckIcon = getFaIcon({ icon: faCheck });
export const FileAltIcon = getFaIcon({ icon: faFileAlt });
export const CommentMedicalIcon = getFaIcon({ icon: faCommentMedical });
export const NewspaperIcon = getFaIcon({ icon: faNewspaper });
export const FileSignatureIcon = getFaIcon({ icon: faFileSignature });
export const SignatureIcon = getFaIcon({ icon: faSignature });
export const ShieldCheckIcon = getFaIcon({ icon: faShieldCheck });
export const IdBadgeIcon = getFaIcon({ icon: faIdBadge });
export const SearchIcon = getFaIcon({ icon: faSearch });
export const InboxIcon = getFaIcon({ icon: faInbox });
export const LightbulbOnIcon = getFaIcon({ icon: faLightbulbOn });
export const ToolsIcon = getFaIcon({ icon: faTools });
export const LanguageIcon = getFaIcon({ icon: faLanguage });
export const CalendarIcon = getFaIcon({ icon: faCalendar });
export const CalendarDayIcon = getFaIcon({ icon: faCalendarDay });
export const ClockIcon = getFaIcon({ icon: faClock });
export const UserClockIcon = getFaIcon({ icon: faUserClock });
export const ChevronRightIcon = getFaIcon({ icon: faChevronRight });
export const MapMarkerAltIcon = getFaIcon({ icon: faMapMarkerAlt });
export const CreditCardFrontIcon = getFaIcon({ icon: faCreditCardFront });
export const GlobeIcon = getFaIcon({ icon: faGlobe });
export const ForwardIcon = getFaIcon({ icon: faForward });
export const TimesIcon = getFaIcon({ icon: faTimes });
export const BusinessTimeIcon = getFaIcon({ icon: faBusinessTime });
export const MobileIcon = getFaIcon({ icon: faMobile });
export const DoorOpenIcon = getFaIcon({ icon: faDoorOpen });
export const QrcodeIcon = getFaIcon({ icon: faQrcode });
export const MagicIcon = getFaIcon({ icon: faMagic });
export const UsersMedicalIcon = getFaIcon({ icon: faUsersMedical });
export const UsersCogIcon = getFaIcon({ icon: faUsersCog });
export const ListUlIcon = getFaIcon({ icon: faListUl });
export const DatabaseIcon = getFaIcon({ icon: faDatabase });
export const UnlockIcon = getFaIcon({ icon: faUnlock });
export const LockIcon = getFaIcon({ icon: faLock });
export const MinusOctagonIcon = getFaIcon({ icon: faMinusOctagon });
export const DebugIcon = getFaIcon({ icon: faDebug });
export const LinkIcon = getFaIcon({ icon: faLink });
export const PreviewIcon = getFaIcon({ icon: faMagnifyingGlass });
export const HelmetSafetyIcon = getFaIcon({ icon: faHelmetSafety });
export const PhoneIcon = getFaIcon({ icon: faPhone });
export const DownloadIcon = getFaIcon({ icon: faDownload });
export const UserTimesIcon = getFaIcon({ icon: faUserTimes });
export const FileChartLineIcon = getFaIcon({ icon: faFileChartLine });
export const BuildingColumnsIcon = getFaIcon({ icon: faBuildingColumns });
export const HandsHoldingChildIcon = getFaIcon({ icon: faHandsHoldingChild });
export const HourglassClockIcon = getFaIcon({ icon: faHourglassClock });
export const TicketIcon = getFaIcon({ icon: faTicket });
export const MobileSignalIcon = getFaIcon({ icon: faMobileSignal });
export const Grid2Icon = getFaIcon({ icon: faGrid2 });
export const LockKeyholeIcon = getFaIcon({ icon: faLockKeyhole });
export const EditIcon = getFaIcon({ icon: faEdit });
export const SaveIcon = getFaIcon({ icon: faSave });
export const EyeIcon = getFaIcon({ icon: faEye });
export const EyeSlashIcon = getFaIcon({ icon: faEyeSlash });

export const MoonStarsSolidIcon = getFaIcon({ icon: fasMoonStars });
export const BriefcaseSolidIcon = getFaIcon({ icon: fasBriefcase });
export const BriefcaseDuotoneIcon = getFaIcon({ icon: fadBriefcase });

export { faCcVisa, faCcMastercard, faCcAmex, faCcDiscover, faCcJcb, faCcDinersClub };
