import { permissions, to, ObjectPermission } from './permissions';
import { AuthUser } from './types';
import { userHasAccess } from './utils';

export * from './types';
export { userHasAnyRole, userHasRole, userHasSomeRoleOtherThan, userIsAdmin, adminRoles } from './utils';

export const userIsAllowed = <T extends to>(
  permission: T,
  user: AuthUser,
  ...objectId: T extends ObjectPermission ? [string] : []
): boolean => {
  if (user.isAdmin) return true;
  const permissionObject = permissions[permission];
  if (typeof permissionObject === 'function') {
    return permissionObject(user, ...objectId);
  }
  return userHasAccess(user.roles, permissionObject, ...objectId);
};
