import React from 'react';
import cn from 'classnames';
import { Avatar } from '@odin-labs/components';
import { getInitialsForUser } from 'utils';
import { CheckIcon, ClockIcon, WrenchIcon } from 'components/icons';
import { AvailableJobsiteWorker } from 'containers/jobsiteFormSubmission/types';

export const classes = {
  itemContainer: ({ isClickable, isSelected }: { isClickable: boolean; isSelected: boolean }): string =>
    cn(
      isClickable && 'odin-cursor-pointer',
      isSelected
        ? 'odin-border-2 odin-border-odin-primary odin-bg-odin-highlight'
        : 'odin-border odin-border-gray-200 odin-shadow-sm',
      'odin-rounded-lg odin-px-5 odin-py-3 sm:odin-px-3 sm:odin-py-5',
    ),
  workerInfoRow: ({ isSelected }: { isSelected: boolean }): string =>
    cn(
      'odin-flex sm:odin-flex-col odin-items-center odin-gap-x-5 odin-gap-y-3',
      !isSelected && 'odin-border odin-border-transparent',
    ),
  avatar: cn('!odin-w-12 !odin-h-12 !odin-text-lg sm:!odin-w-20 sm:!odin-h-20 sm:!odin-text-3xl'),
  workerInfoContainer: cn('odin-flex odin-flex-col odin-grow odin-justify-center'),
  workerName: cn(
    'odin-flex odin-justify-center odin-items-center',
    'odin-text-base odin-font-medium odin-text-gray-900 odin-truncate',
    'odin-whitespace-pre-line odin-text-center',
  ),
  workerInfoItem: cn(
    'worker-info-item-container',
    'odin-flex odin-justify-center odin-items-center',
    'odin-text-1.5xs odin-truncate',
    'odin-whitespace-pre-line odin-text-center',
  ),
  workerInfoItemIcon: cn('worker-info-item-icon odin-text-odin-primary odin-text-sm odin-font-normal odin-mr-1.5'),
  contractor: cn('odin-border odin-border-gray-200 odin-rounded-lg odin-mt-1.5 odin-px-2.5 odin-py-1'),
  participantTypeContainer: cn('odin-space-x-5'),
  checkIcon: (extraClasses: string): string =>
    cn(extraClasses, 'odin-text-base odin-bg-odin-primary odin-text-white odin-rounded-full odin-p-1'),
  clockIcon: cn(
    'odin-text-xs sm:odin-text-base',
    'odin-absolute odin-ml-12 sm:odin-ml-15 odin-bottom-0',
    'odin-bg-odin-success odin-text-white odin-rounded-full odin-p-1',
  ),
};

export type FormSubmissionSelectableWorkerProps = {
  jobsiteWorker: AvailableJobsiteWorker;
  isSelected: boolean;
  showContractor?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

export function FormSubmissionSelectableWorker(props: FormSubmissionSelectableWorkerProps): React.ReactElement {
  const { jobsiteWorker, isSelected, showContractor, onClick } = props;
  const { isOnSite, contractorWorker } = jobsiteWorker ?? {};
  const { worker, contractor } = contractorWorker ?? {};
  const { user, trade, jobTitle, profilePicture } = worker ?? {};
  const { firstName, lastName } = user?.identity ?? {};
  const { downloadUrl: imageUrl } = profilePicture ?? {};

  return (
    <div className={classes.itemContainer({ isSelected, isClickable: !!onClick })} onClick={onClick}>
      <div className={classes.workerInfoRow({ isSelected })}>
        <div className="odin-relative odin-flex odin-justify-center odin-h-full">
          <Avatar
            className={classes.avatar}
            size="3xl"
            src={imageUrl}
            placeholder={getInitialsForUser(user)}
            objectFit="cover"
          />
          {isSelected && (
            <CheckIcon
              className={classes.checkIcon('odin-hidden sm:odin-block odin-absolute odin-ml-11 sm:odin-ml-15')}
            />
          )}
          {isOnSite && <ClockIcon className={classes.clockIcon} />}
        </div>
        <div className={classes.workerInfoContainer}>
          <div className={classes.workerName}>
            {firstName} {lastName}
          </div>
          <div className={classes.workerInfoItem}>{[trade, jobTitle].filter(Boolean).join(', ')}</div>
          {showContractor && (
            <div className={cn(classes.workerInfoItem, classes.contractor)}>
              <WrenchIcon className={classes.workerInfoItemIcon} />
              <span>{contractor?.organization.name}</span>
            </div>
          )}
        </div>
        {isSelected && <CheckIcon className={classes.checkIcon('sm:odin-hidden')} />}
      </div>
    </div>
  );
}
