import { SelectOptionElement } from '@odin-labs/components';
import { UserAssignmentType, UserRole, UserRoleKey } from 'apollo/generated/client-operations';
import { adminRoles } from 'acl';
import { ensureNonEmptyItems } from 'utils';
import { isContractorMemberRoleKey } from 'utils/contractorMemberUser';
import { JobsiteForOption, UserRoleType } from './types';

export type { JobsiteForOption };
export const getJobsitesOptions = (jobsites: JobsiteForOption[]): SelectOptionElement[] =>
  jobsites
    ?.map(({ jobsiteId: value, name: label }) => ({ value, label }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const getUserRoleType = (userRoleKey: UserRoleKey): UserRoleType | undefined => {
  if (userRoleKey) {
    if (isContractorMemberRoleKey(userRoleKey)) {
      return UserAssignmentType.Contractor;
    }
    if (userRoleKey === UserRoleKey.ClientAdmin) {
      return UserAssignmentType.Developer;
    }
    return UserAssignmentType.Jobsite;
  }
  return undefined;
};

export const getUserRoleTypes = (userRoleKey: UserRoleKey, isAdminUser: boolean): UserRoleType[] | undefined => {
  if (userRoleKey) {
    if (isContractorMemberRoleKey(userRoleKey)) {
      return [UserAssignmentType.Contractor];
    }
    return ensureNonEmptyItems([UserAssignmentType.Jobsite, isAdminUser && UserAssignmentType.Developer]);
  }
  return undefined;
};

export type UserRoleForOption = Pick<UserRole, 'displayName' | 'key'>;
export const getUserRolesOptions = ({
  userRoles,
  userRoleTypes,
  showAdminRoles,
}: {
  userRoles: UserRoleForOption[];
  userRoleTypes?: UserRoleType[];
  showAdminRoles?: boolean;
}): SelectOptionElement<UserRoleKey>[] => {
  const filteredUserRoles = (
    userRoleTypes ? userRoles?.filter(({ key }) => userRoleTypes.includes(getUserRoleType(key))) : userRoles
  )?.filter(({ key }) => showAdminRoles || !adminRoles.includes(key));
  return filteredUserRoles?.map(({ key: value, displayName: label }) => ({ value, label }));
};
