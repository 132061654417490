import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Icon } from '@odin-labs/components';
import {
  OnboardingStepKey,
  OnboardingStepName,
  JobsiteOnboardingDocument,
  JobsiteOnboardingField,
  JobsiteModule,
  JobsiteOnboardingModule,
  JobsiteOnboardingStep,
  JobsiteWorker,
  Jobsite,
} from 'containers/workerOnboarding/types';
import { DocumentKey } from 'containers/worker/utils';
import {
  CheckIcon,
  CommentMedicalIcon,
  ExclamationTriangleIcon,
  FileAltIcon,
  FileSignatureIcon,
  HardHatIcon,
  IdBadgeIcon,
  NewspaperIcon,
  PencilIcon,
  UserIcon,
} from 'components/icons';
import { SidebarItem, SidebarLayout, SidebarProps } from 'containers/sidebar/types';
import { Env, getEnv } from 'containers/sidebar/utils';

export const getOnboardingModule = (modules: JobsiteModule[]): JobsiteOnboardingModule =>
  modules?.find((m): m is JobsiteOnboardingModule => {
    return m.__typename === 'JobsiteOnboardingModule'; // eslint-disable-line no-underscore-dangle
  });

export const getStepsConfig = (modules: JobsiteModule[]): Partial<Record<string, JobsiteOnboardingStep>> => {
  const jobsiteOnboardingModule = getOnboardingModule(modules);
  return jobsiteOnboardingModule?.steps?.reduce((result, step) => ({ ...result, [step.key]: step }), {}) ?? {};
};

export const getStepConfig = (modules: JobsiteModule[], stepKey: OnboardingStepKey): JobsiteOnboardingStep => {
  const jobsiteOnboardingModule = getOnboardingModule(modules);
  return jobsiteOnboardingModule?.steps?.find((step) => step.key === stepKey);
};

export const getVisibleSteps = (jobsite: Jobsite): string[] => {
  if (!jobsite) return null;
  const jobsiteOnboardingModule = getOnboardingModule(jobsite.modules);
  return jobsiteOnboardingModule?.steps?.filter((step) => step.isVisible).map(({ key }) => key);
};

export const getCompletedSteps = (jobsiteWorker: JobsiteWorker): Record<OnboardingStepKey, boolean> => {
  return {
    [OnboardingStepKey.PersonalInfo]: !!jobsiteWorker.profileCompletedAt,
    [OnboardingStepKey.WorkDocuments]: !!jobsiteWorker.documentsCompletedAt,
    [OnboardingStepKey.MedicalInfo]: !!jobsiteWorker.passedMedicalExamAt,
    [OnboardingStepKey.OnboardingTraining]: !!jobsiteWorker.onboardingSignedAt,
    [OnboardingStepKey.Signature]: !!jobsiteWorker.signatureCompletedAt,
    [OnboardingStepKey.Badging]: !!jobsiteWorker.badgingCompletedAt,
    [OnboardingStepKey.SiteSpecificOrientation]: !!jobsiteWorker.siteSpecificTrainingCompletedAt,
  };
};

type StatusKey = 'failed' | 'skipped' | 'completed' | 'active' | 'default';

const statusInfo: Record<StatusKey, { text: string; color: string; icon: Icon }> = {
  failed: {
    text: 'Did not complete',
    color: cn('odin-text-odin-danger'),
    icon: ExclamationTriangleIcon,
  },
  skipped: {
    text: 'Skipped',
    color: cn('odin-text-[#ffb800]'), // cn('odin-text-odin-warning'),
    icon: ExclamationTriangleIcon,
  },
  completed: {
    text: 'Completed',
    color: cn('odin-text-odin-success'),
    icon: CheckIcon,
  },
  active: {
    text: 'In progress',
    color: cn('odin-text-odin-primary'),
    icon: PencilIcon,
  },
  default: {
    text: 'Not started',
    color: cn('odin-text-[#8b93a1]'), // cn('odin-text-gray-400'),
    icon: ExclamationTriangleIcon,
  },
};

type GetStatusArgs = {
  failed: boolean;
  skipReason: string;
  completed: boolean;
  active: boolean;
};

const getStatusKey = ({ failed, skipReason, completed, active }: GetStatusArgs): StatusKey => {
  if (failed) return 'failed';
  if (skipReason) return 'skipped';
  if (completed) return 'completed';
  if (active) return 'active';
  return 'default';
};

const getStatus = (args: GetStatusArgs): SidebarItem['status'] => {
  const statusKey = getStatusKey(args);
  const { text, ...restStatus } = statusInfo[statusKey];
  const { skipReason } = args;
  return { ...restStatus, text: statusKey === 'skipped' ? `${text} - ${skipReason}` : text };
};

type OnboardingSidebarConfigItem = {
  key: OnboardingStepKey;
  text: OnboardingStepName;
  icon: Icon;
  link?: SidebarItem['link'];
  skipReason: string;
};

export const getSidebarItems = (
  jobsiteWorker: JobsiteWorker,
  visibleSteps: string[] | null,
  currentStep: string,
): SidebarItem[] => {
  if (!jobsiteWorker) return null;

  const { jobsiteWorkerId } = jobsiteWorker;
  const completedSteps = getCompletedSteps(jobsiteWorker);
  const failed = !!jobsiteWorker.bannedReason;

  const sidebarConfig: OnboardingSidebarConfigItem[] = [
    {
      key: OnboardingStepKey.PersonalInfo,
      text: OnboardingStepName.PersonalInfo,
      icon: UserIcon,
      skipReason: jobsiteWorker.profileCompleteSkipReason,
    },
    {
      key: OnboardingStepKey.WorkDocuments,
      text: OnboardingStepName.WorkDocuments,
      icon: FileAltIcon,
      skipReason: jobsiteWorker.documentsCompletedSkipReason,
    },
    {
      key: OnboardingStepKey.MedicalInfo,
      text: OnboardingStepName.MedicalInfo,
      icon: CommentMedicalIcon,
      skipReason: jobsiteWorker.medicalExamSkipReason,
    },
    {
      key: OnboardingStepKey.OnboardingTraining,
      text: OnboardingStepName.OnboardingTraining,
      icon: NewspaperIcon,
      skipReason: jobsiteWorker.onboardingSkipReason,
    },
    {
      key: OnboardingStepKey.Signature,
      text: OnboardingStepName.Signature,
      icon: FileSignatureIcon,
      skipReason: jobsiteWorker.signatureSkipReason,
    },
    {
      key: OnboardingStepKey.Badging,
      text: OnboardingStepName.Badging,
      icon: IdBadgeIcon,
      skipReason: jobsiteWorker.badgingSkipReason,
    },
    {
      key: OnboardingStepKey.SiteSpecificOrientation,
      text: OnboardingStepName.SiteSpecificOrientation,
      icon: HardHatIcon,
      skipReason: jobsiteWorker.siteSpecificTrainingSkipReason,
    },
  ];

  const sidebarItems = sidebarConfig.map((sbc): SidebarItem => {
    const status = getStatus({
      failed,
      skipReason: sbc.skipReason,
      completed: completedSteps[sbc.key],
      active: currentStep === sbc.key,
    });

    return {
      key: sbc.key,
      acl: null,
      icon: sbc.icon,
      link: sbc.link ?? Link,
      path: `/onboarding/${jobsiteWorkerId}/${sbc.key}`,
      text: sbc.text,
      locked: false,
      status,
    };
  });

  const stepsConfig = getStepsConfig(jobsiteWorker.jobsiteContractor?.jobsite?.modules);

  return sidebarItems
    .filter(({ key }) => visibleSteps?.includes(key))
    .sort((a, b) => (stepsConfig[a.key]?.index ?? 999) - (stepsConfig[b.key]?.index ?? 999));
};

export const isValidStep = (step: string): step is OnboardingStepKey =>
  Object.values<string>(OnboardingStepKey).includes(step);

export const getDocumentsConfig = (
  modules: JobsiteModule[],
): Partial<Record<DocumentKey, JobsiteOnboardingDocument>> => {
  const jobsiteOnboardingModule = getOnboardingModule(modules);
  return (
    jobsiteOnboardingModule?.documents?.reduce((result, document) => ({ ...result, [document.key]: document }), {}) ??
    {}
  );
};

export const getVisibleDocuments = (jobsiteWorker: JobsiteWorker): string[] => {
  if (!jobsiteWorker) return null;

  const { jobsite } = jobsiteWorker?.jobsiteContractor ?? {};
  const jobsiteOnboardingModule = getOnboardingModule(jobsite.modules);
  return jobsiteOnboardingModule?.documents?.filter((doc) => doc.isVisible).map(({ key }) => key);
};

export const getDocumentFieldsConfig = (
  documentKey: DocumentKey,
  jobsiteWorker: JobsiteWorker,
): Record<string, JobsiteOnboardingField> => {
  if (!jobsiteWorker) return {};

  const { jobsite } = jobsiteWorker?.jobsiteContractor ?? {};

  const documentsConfig = getDocumentsConfig(jobsite.modules)[documentKey];
  return documentsConfig?.fields?.reduce((result, field) => ({ ...result, [field.key]: field }), {}) ?? {};
};

export const getStepFieldsConfig = (
  stepKey: OnboardingStepKey,
  jobsiteWorker: JobsiteWorker,
): Record<string, JobsiteOnboardingField> => {
  if (!jobsiteWorker) return {};

  const { jobsite } = jobsiteWorker?.jobsiteContractor ?? {};

  const stepConfig = getStepConfig(jobsite.modules, stepKey);
  return stepConfig?.fields?.reduce((result, field) => ({ ...result, [field.key]: field }), {}) ?? {};
};

const sidebarClasses: Record<SidebarLayout | 'default', { item: string; header: string; footer: string }> = {
  default: {
    item: cn(
      'odin-group odin-flex odin-flex-col odin-rounded-md',
      'hover:odin-text-black hover:odin-transition-all hover:odin-duration-300',
    ),
    header: cn('odin-flex odin-items-center odin-shrink-0'),
    footer: cn('odin-flex odin-justify-center odin-px-6 odin-py-5'),
  },
  desktop: {
    item: cn('odin-p-3 odin-text-base'),
    header: cn('odin-px-2'),
    footer: '',
  },
  tablet: {
    item: cn('odin-flex-col odin-p-2'),
    header: cn('odin-px-3 odin-text-center'),
    footer: '',
  },
  mobile: {
    item: cn('odin-p-3 odin-text-base'),
    header: cn('odin-px-2'),
    footer: '',
  },
};

const backgroundClasses: Record<'default' | Env, string> = {
  default: cn('odin-bg-odin-env-prod'),
  prd: cn('odin-bg-odin-env-prod'),
  demo: cn('odin-bg-odin-env-demo'),
  stg: cn('odin-bg-odin-env-stg'),
  qa: cn('odin-bg-odin-env-qa'),
  dev: cn('odin-bg-odin-env-dev'),
  local: cn('odin-bg-odin-env-local'),
};

const iconClasses: Record<'default' | Env, string> = {
  default: cn('odin-text-odin-primary'),
  prd: cn('odin-text-odin-primary'),
  demo: cn('odin-text-odin-primary'),
  stg: cn('odin-text-green-600'),
  qa: cn('odin-text-yellow-600'),
  dev: cn('odin-text-odin-env-dev'),
  local: cn('odin-text-odin-env-local'),
};

const getItemClassesFn = (
  colorClass: string = cn('odin-text-gray-600'),
  bgColor: string = cn('odin-bg-gray-200'),
  bgHoverClass: string = cn('hover:odin-bg-gray-100'),
): SidebarProps['classes']['item'] => {
  return (isActive: boolean, layout: SidebarLayout): string =>
    cn(
      sidebarClasses.default.item,
      sidebarClasses[layout].item,
      colorClass,
      isActive ? bgColor : bgHoverClass,
      'hover:odin-text-black hover:odin-transition-all hover:odin-duration-300',
    );
};

const headerClassesFn = (layout: SidebarLayout): string => {
  return cn(sidebarClasses.default.header, sidebarClasses[layout].header);
};

const footerClassesFn = (layout: SidebarLayout): string => {
  return cn(sidebarClasses.default.footer, sidebarClasses[layout].footer);
};

export const getClasses = (): SidebarProps['classes'] => {
  const env = getEnv();
  const defaultBackground = cn('odin-bg-gray-50');

  return {
    background: defaultBackground ?? backgroundClasses[env] ?? backgroundClasses.default,
    item: getItemClassesFn(),
    icon: (isActive: boolean): string => cn(isActive && (iconClasses[env] ?? iconClasses.default)),
    header: headerClassesFn,
    footer: footerClassesFn,
  };
};
