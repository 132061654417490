import React from 'react';
import { UserAssignmentType, useUserUpdateAssignmentMutation } from 'apollo/generated/client-operations';
import { AuthContext } from 'auth';
import { useAvailableJobsites } from 'graphql/client/useAvailableJobsites';
import { isEmpty, useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { FormOnSubmit, ModalForm } from 'components/form';
import { AlertService } from 'components/alertService';
import { LoadingError } from 'components/loadingError';
import { DeveloperForOption } from 'containers/jobsites/helpers/utils';
import { ContractorForOption } from 'containers/contractor/helpers';
import { getUpdateInput, getDefaultValues, getFormInputsHook } from './EditUserRoleModal.forms';
import { EditUserRoleFormData, EditUserRoleModalProps, JobsiteForOption } from './types';
import { useUserModalOptions } from './useUserModalOptions';

export function EditUserRoleModal(props: EditUserRoleModalProps): React.ReactElement {
  const {
    isOpen,
    onCancel,
    onConfirm,
    workerId,
    memberUserAssignmentId,
    memberUserAccountId,
    userRoleType,
    userRoles,
    memberUserAssignmentObjectId,
    memberUserAssignmentObjectName,
    userRoleKey,
    title,
  } = props;

  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = React.useState(false);
  const { currentUser: user } = React.useContext(AuthContext);

  const [updateUserAssignment] = useUserUpdateAssignmentMutation();

  const onSubmit: FormOnSubmit<EditUserRoleFormData> = async (data, event, dirtyFields): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      if (isEmpty(dirtyFields)) return;
      const input = getUpdateInput(userRoleType, memberUserAssignmentId, data, dirtyFields);
      await updateUserAssignment({ variables: { input } });

      if (userRoleType === UserAssignmentType.Jobsite && user.userAccountId === memberUserAccountId) {
        useAvailableJobsites.resetCacheOnNextCall();
      }
      onConfirm();
      AlertService.alert('success', 'Success', 'User role successfully added');
    } catch (error) {
      event.preventDefault();
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) setIsSaving(false);
    }
  };

  const jobsite: JobsiteForOption =
    userRoleType === UserAssignmentType.Jobsite
      ? {
          jobsiteId: memberUserAssignmentObjectId,
          name: memberUserAssignmentObjectName,
        }
      : null;
  const contractor: ContractorForOption =
    userRoleType === UserAssignmentType.Contractor
      ? {
          contractorId: memberUserAssignmentObjectId,
          organization: {
            name: memberUserAssignmentObjectName,
          },
        }
      : null;
  const developer: DeveloperForOption =
    userRoleType === UserAssignmentType.Developer
      ? {
          developerId: memberUserAssignmentObjectId,
          organization: {
            name: memberUserAssignmentObjectName,
          },
        }
      : null;

  const { userRolesOptions, contractorsOptions, jobsitesOptions, developersOptions, error } = useUserModalOptions({
    editType: 'update',
    userRoleTypes: userRoleType && [userRoleType],
    userRoles,
    workerId,
    jobsite,
    contractor,
    developer,
  });

  const formInputs = getFormInputsHook({
    editType: 'update',
    userRoleType,
    userRolesOptions,
    jobsitesOptions,
    contractorsOptions,
    developersOptions,
  });

  const defaultValues = React.useMemo(
    () =>
      getDefaultValues({
        userRoleKey,
        title,
        userAssignmentType: userRoleType,
        userAssignmentObjectId: memberUserAssignmentObjectId,
        userRolesOptions,
        jobsitesOptions,
        contractorsOptions,
        developersOptions,
      }),
    [
      userRoleKey,
      title,
      userRoleType,
      memberUserAssignmentObjectId,
      userRolesOptions,
      jobsitesOptions,
      contractorsOptions,
      developersOptions,
    ],
  );

  if (error) {
    return <LoadingError error={error} />;
  }

  return (
    <ModalForm
      open={isOpen}
      setOpen={onCancel}
      title="Edit User Role"
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-4 odin-gap-6"
      actionText="Save"
      actionButtonWithSpinner={isSaving}
      onIsDirtyChange={setIsFormDirty}
      actionButtonEnabled={isFormDirty}
    />
  );
}
