import React, { Fragment } from 'react';
import cn from 'classnames';
import { Dialog, Transition } from '@headlessui/react';
import { AuthContext } from 'auth';
import { generatePath } from 'utils/generatePath';
import { LockIcon, TimesIcon } from 'components/icons';
import { SidebarProps } from './types';

export function MobileSidebar(props: SidebarProps): React.ReactElement {
  const { isSidebarOpen, closeSidebar, items, getItemProps, classes, header, footer } = props;
  const { currentUser: user } = React.useContext(AuthContext);

  return (
    <Transition.Root show={isSidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="sidebar-mobile odin-fixed odin-inset-0 odin-flex odin-z-40 md:odin-hidden"
        open={isSidebarOpen}
        onClose={closeSidebar}
      >
        <Transition.Child
          as={Fragment}
          enter="odin-transition-opacity odin-ease-linear odin-duration-300"
          enterFrom="odin-opacity-0"
          enterTo="odin-opacity-100"
          leave="odin-transition-opacity odin-ease-linear odin-duration-300"
          leaveFrom="odin-opacity-100"
          leaveTo="odin-opacity-0"
        >
          {/* overlay */}
          <div className={cn('odin-fixed odin-inset-0 odin-bg-gray-900/50 odin-transition-opacity')} />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="odin-transition odin-ease-in-out odin-duration-300 odin-transform"
          enterFrom="odin--translate-x-full"
          enterTo="odin-translate-x-0"
          leave="odin-transition odin-ease-in-out odin-duration-300 odin-transform"
          leaveFrom="odin-translate-x-0"
          leaveTo="odin--translate-x-full"
        >
          <Dialog.Panel className={cn(classes.background, 'odin-relative odin-flex odin-flex-col odin-max-w-xs')}>
            <Transition.Child
              as={Fragment}
              enter="odin-ease-in-out odin-duration-300"
              enterFrom="odin-opacity-0"
              enterTo="odin-opacity-100"
              leave="odin-ease-in-out odin-duration-300"
              leaveFrom="odin-opacity-100"
              leaveTo="odin-opacity-0"
            >
              <div className="odin-absolute odin-top-0 odin-right-0 odin--mr-12 odin-pt-2">
                <button
                  className={cn(
                    'odin-ml-1',
                    'odin-flex',
                    'odin-items-center',
                    'odin-justify-center',
                    'odin-h-10',
                    'odin-w-10',
                    'odin-rounded-full',
                    'focus:odin-outline-none',
                    'focus:odin-ring-2',
                    'focus:odin-ring-inset',
                    'focus:odin-ring-white',
                  )}
                  onClick={closeSidebar}
                >
                  <span className="odin-sr-only">Close sidebar</span>
                  <TimesIcon className="odin-h-6 odin-w-6 odin-text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="odin-flex-1 odin-h-0 odin-pt-5 odin-pb-4 odin-px-6 odin-overflow-y-auto">
              <div className={classes.header('mobile')}>{header}</div>
              <nav className="odin-mt-5 odin-space-y-1">
                {items.map((item): React.ReactElement => {
                  if (!item.acl || user.hasAnyRole(item.acl)) {
                    const { isLocked, isActive, openInNewTabProps } = getItemProps(item);
                    const { link: Link, icon: Icon, status } = item;
                    const { text: statusText, color: statusColor, icon: StatusIcon } = status ?? {};

                    return (
                      <Link
                        key={item.path}
                        to={generatePath(item.path, {})}
                        onClick={closeSidebar}
                        className={cn(classes.item(isActive, 'mobile'), 'odin-p-3 odin-text-base')}
                        {...openInNewTabProps}
                      >
                        <div className="odin-flex odin-items-center odin-gap-x-3">
                          {Icon && (
                            <Icon
                              className={cn(
                                classes.icon(isActive, 'mobile'),
                                'odin-text-lg odin-h-5 odin-w-6 odin-shrink-0',
                              )}
                            />
                          )}
                          <span className="odin-whitespace-nowrap">{item.text}</span>
                          {isLocked && <LockIcon />}
                        </div>
                        {statusText && (
                          <div className={cn(statusColor, 'odin-flex odin-items-center odin-mt-2 odin-gap-x-2')}>
                            {StatusIcon && <StatusIcon />} {statusText}
                          </div>
                        )}
                      </Link>
                    );
                  }
                  return null;
                })}
              </nav>
            </div>
            <div className={classes.footer('mobile')}>{footer}</div>
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
