import { tz } from 'moment-timezone';
import { SelectOptionElement } from '@odin-labs/components';
import { StateAbbreviation } from 'apollo/generated/client-operations';
import { camelToTitleCase, pascalToSnakeCase, titleToSnakeCase } from 'utils';

export type FancySelectOption = {
  value: string;
  label: string;
  key?: string;
};

export const toFancySelectOptions = (options: Array<string>): Array<FancySelectOption> =>
  options.map((option) => ({
    value: option,
    label: option,
    key: titleToSnakeCase(option),
  }));

export const getSelectOptions = <T extends string>({
  values,
  labelGetter = camelToTitleCase,
  keyGetter = pascalToSnakeCase,
}: {
  values: T[];
  keyGetter?: (value: T) => string;
  /** Pass null to display value as label */
  labelGetter?: (value: T) => string;
}): SelectOptionElement<T>[] => {
  return values?.map((value) => ({
    value,
    label: labelGetter?.(value) ?? value,
    key: keyGetter?.(value),
  }));
};

type Flatten<T> = T extends unknown[] ? T[number] : T;

export const getDefaultSelectOption = <
  TValue extends string | string[],
  TOption extends SelectOptionElement<Flatten<TValue>>,
>(
  options: TOption[],
  value: TValue,
): TValue extends unknown[] ? TOption[] : TOption => {
  if (Array.isArray(value)) {
    return (options?.filter((opt) => value.includes(opt.value)) ?? null) as never;
  }
  return (options?.find((opt) => opt.value === value) ?? null) as never;
};

export const veteranOptions = ['Veteran', 'Non veteran'];

export const raceOptions = [
  'White',
  'Black/African American',
  'Hispanic/Latino',
  'Asian/Native Hawaiian or Pacific Islander',
  'Native American/Alaskan Native',
  'Other/Prefer not to respond',
];

export const genderOptions = ['Male', 'Female', 'Other'];

export const primaryLanguageOptions = [
  'English',
  'Spanish',
  'Chinese',
  'Russian',
  'Hungarian',
  'Polish',
  'French',
  'Other',
];

export const emergencyContactRelationshipOptions = [
  'Boyfriend',
  'Child',
  'Co-worker',
  'Family',
  'Girlfriend',
  'Grandparent',
  'Friend',
  'Parent',
  'Partner',
  'Spouse',
  'Other',
];

export const tradeOptions = [
  'Acoustics',
  'Architect',
  'Audio/Visual Technician',
  'BIM Engineer',
  'BMS',
  'Bricklayer',
  'Cable  ',
  'Cable Installer',
  'Cable Technician',
  'Carpenter',
  'Cement Mason',
  'Cleaner',
  'Commissioning Engineer',
  'Communications',
  'Concrete Safety Manager',
  'Concrete Worker',
  'Construction Manager',
  'Consultant',
  'Controls',
  'Crane Operator',
  'Data Center',
  'Data Technician',
  'Delivery',
  'Demolition',
  'Derrickman',
  'Dockbuilder',
  'Docker',
  'Drill Operator',
  'Driver',
  'Drywall',
  'Electrician',
  'Elevator',
  'Engineer',
  'Estimator',
  'Excavator',
  'Executive',
  'Fiber Optics',
  'Fire Alarm Technician',
  'Fire and/or Life Safety',
  'Fire Safety',
  'Flagger',
  'Flooring',
  'General Contractor',
  'Glass',
  'Glazier',
  'Helper',
  'HVAC',
  'Inspector',
  'Installer',
  'Insulator',
  'Ironworker',
  'IT',
  'Laborer',
  'Landscaping',
  'Lather',
  'Lighting',
  'Locksmith',
  'Low Voltage',
  'Maintenance',
  'Management',
  'Manager',
  'Marble',
  'Masonry',
  'Mechanic',
  'Medical',
  'MEP Engineer',
  'Metal Lathers',
  'Metalwork',
  'Millwork',
  'Mover',
  'Office',
  'Oiler',
  'Operating Engineer',
  'Operator',
  'Ornamental Ironworker',
  'Other',
  'Owner',
  'Painter',
  'Pipefitter',
  'Plasterer',
  'Plumber',
  'Programmer',
  'Project Manager',
  'Rebar',
  'Rigger',
  'Roofer',
  'Safety',
  'Scaffolding',
  'Scanning',
  'Security',
  'Sheetmetal',
  'Sprinkler',
  'Steamfitter',
  'Steelworker',
  'Stonework',
  'Superintendent',
  'Supervisor',
  'Surveyor',
  'Taper',
  'Tapers',
  'Teamsters',
  'Technician',
  'Technology',
  'Telecommunications',
  'Terrazzo',
  'Testing & Balancing',
  'Tile & Marble',
  'Tiler',
  'Timekeeper',
  'Tin Knocker',
  'Union Representative',
  'Wall Coverings',
  'Waterproofer',
  'Welder',
  'Windows',
  'Wood Flooring',
  'Woodwork',
];

export const jobTitleOptions = [
  'Apprentice',
  'Assistant',
  'Competent Person',
  'Director',
  'Executive',
  'Executive Vice President',
  'Field Supervisor',
  'Foreman',
  'General Foreman',
  'Head of Security',
  'Journeyman',
  'Laborer',
  'Manager',
  'Medic',
  'Medic Supervisor',
  'Office',
  'Operating Engineer',
  'Other',
  'Project Manager',
  'Provisional Apprentice',
  'Provisional Foreman',
  'Provisional Journeyman',
  'Senior Director',
  'Senior Superintendent',
  'Shop Steward',
  'Superintendent',
  'Supervisor',
  'Vice President',
];

export const unionAffiliationOptions = [
  'Non Union',
  'DC 711',
  'DC 806',
  'DC 9',
  'DC 16',
  'Local 1',
  'Local 10',
  'Local 1010',
  'Local 1087',
  'Local 12',
  'Local 1281',
  'Local 14',
  'Local 15',
  'Local 15A',
  'Local 15B',
  'Local 15C',
  'Local 15D',
  'Local 15E',
  'Local 15F',
  'Local 15G',
  'Local 15H',
  'Local 150',
  'Local 152',
  'Local 1556',
  'Local 154',
  'Local 155',
  'Local 157',
  'Local 16A',
  'Local 17',
  'Local 18',
  'Local 18A',
  'Local 1964',
  'Local 1974',
  'Local 1967',
  'Local 1976',
  'Local 20',
  'Local 212',
  'Local 229',
  'Local 25',
  'Local 253',
  'Local 254',
  'Local 255',
  'Local 262',
  'Local 279',
  'Local 28',
  'Local 280',
  'Local 282',
  'Local 29',
  'Local 290',
  'Local 295',
  'Local 3',
  'Local 30',
  'Local 313',
  'Local 342',
  'Local 361',
  'Local 368',
  'Local 38',
  'Local 399',
  'Local 4',
  'Local 40',
  'Local 402',
  'Local 43',
  'Local 433',
  'Local 44',
  'Local 45',
  'Local 451',
  'Local 456',
  'Local 46',
  'Local 472',
  'Local 48',
  'Local 5',
  'Local 551',
  'Local 522',
  'Local 580',
  'Local 6',
  'Local 608',
  'Local 63',
  'Local 638',
  'Local 64',
  'Local 6A',
  'Local 7',
  'Local 711',
  'Local 731',
  'Local 780',
  'Local 79',
  'Local 8',
  'Local 825',
  'Local 84',
  'Local 846',
  'Local 848A',
  'Local 926',
  'Local 94',
  'Local 814',
  'Local 197',
  'Local 66',
  'Local 363',
  'NA',
  'NRCC',
  'NU',
  'NYCDCC',
  'Office',
];

export const oshaCardTypes = [
  'OSHA 10 hour construction',
  'OSHA 30 hour construction',
  'OSHA construction trainer card',
  'Disaster Site Trainer card',
  'Maritime Standards card',
];

export const sstCardTypes = ['Temporary', 'Limited Site', 'Site Safety', 'Site Safety Training Supervisor'];

export const govCardTypes = ['Driver license', 'Other government ID'];

export const otherCardTypes = [
  'COVID-19 survey',
  'CPR/First Aid, FDNY - F01 (Citywide Fire Guard for Impairment)',
  'FDNY - F60 (Fire Guard for Torch Operations)',
  'FDNY - G40 (Use of LPG or Natural Gas)',
  'FDNY - G60 (Torch Use of Flammable Gas)',
  'FDNY - S56 (Construction Site Fire Safety Manager)',
  'FDNY - S92 (Supervise Portable Fueled Space Heaters)',
  'FDNY - S93 (Supervise Flammable/Combustible Liquids)',
  'NCCO - Crane Operator',
  'NCCO - Lift Director',
  'NCCO - Rigger',
  'NYC DOB - Crane Operator',
  'NYC DOB - Flagman',
  'NYC DOB - Forklift',
  'NYC DOB - Hazwoper',
  'NYC DOB - Lift Director',
  'NYC DOB - Rigger',
  'NYC DOB - Scaffold - (04 Hour)',
  'NYC DOB - Scaffold - (16 Hour Suspended)',
  'NYC DOB - Scaffold (32 Hour Supported Builder)',
  'NYC DOB - Scaffold - (32 Hour Suspended Builder)',
  'NYC DOB - Signal Person',
  'OSHA 10',
  'OSHA 30',
  'OSHA 500',
  'Other',
];

export const skipStepReasons = ['Completed on paper', 'Tech issue', 'Temporarily allowing to skip step', 'Other'];

export const alcoholBreathTestOptions = toFancySelectOptions(['Pass', 'No pass']);
export const medicalTestOptions = toFancySelectOptions(['Pass', 'No pass', 'Conditional pass']);

export const statesOptions = [
  {
    label: 'AL',
    value: StateAbbreviation.Al,
  },
  {
    label: 'AK',
    value: StateAbbreviation.Ak,
  },
  {
    label: 'AZ',
    value: StateAbbreviation.Az,
  },
  {
    label: 'AR',
    value: StateAbbreviation.Ar,
  },
  {
    label: 'CA',
    value: StateAbbreviation.Ca,
  },
  {
    label: 'CO',
    value: StateAbbreviation.Co,
  },
  {
    label: 'CT',
    value: StateAbbreviation.Ct,
  },
  {
    label: 'DE',
    value: StateAbbreviation.De,
  },
  {
    label: 'DC',
    value: StateAbbreviation.Dc,
  },
  {
    label: 'FL',
    value: StateAbbreviation.Fl,
  },
  {
    label: 'GA',
    value: StateAbbreviation.Ga,
  },
  {
    label: 'HI',
    value: StateAbbreviation.Hi,
  },
  {
    label: 'ID',
    value: StateAbbreviation.Id,
  },
  {
    label: 'IL',
    value: StateAbbreviation.Il,
  },
  {
    label: 'IN',
    value: StateAbbreviation.In,
  },
  {
    label: 'IA',
    value: StateAbbreviation.Ia,
  },
  {
    label: 'KS',
    value: StateAbbreviation.Ks,
  },
  {
    label: 'KY',
    value: StateAbbreviation.Ky,
  },
  {
    label: 'LA',
    value: StateAbbreviation.La,
  },
  {
    label: 'ME',
    value: StateAbbreviation.Me,
  },
  {
    label: 'MD',
    value: StateAbbreviation.Md,
  },
  {
    label: 'MA',
    value: StateAbbreviation.Ma,
  },
  {
    label: 'MI',
    value: StateAbbreviation.Mi,
  },
  {
    label: 'MN',
    value: StateAbbreviation.Mn,
  },
  {
    label: 'MS',
    value: StateAbbreviation.Ms,
  },
  {
    label: 'MO',
    value: StateAbbreviation.Mo,
  },
  {
    label: 'MT',
    value: StateAbbreviation.Mt,
  },
  {
    label: 'NE',
    value: StateAbbreviation.Ne,
  },
  {
    label: 'NV',
    value: StateAbbreviation.Nv,
  },
  {
    label: 'NH',
    value: StateAbbreviation.Nh,
  },
  {
    label: 'NJ',
    value: StateAbbreviation.Nj,
  },
  {
    label: 'NM',
    value: StateAbbreviation.Nm,
  },
  {
    label: 'NY',
    value: StateAbbreviation.Ny,
  },
  {
    label: 'NC',
    value: StateAbbreviation.Nc,
  },
  {
    label: 'ND',
    value: StateAbbreviation.Nd,
  },
  {
    label: 'OH',
    value: StateAbbreviation.Oh,
  },
  {
    label: 'OK',
    value: StateAbbreviation.Ok,
  },
  {
    label: 'OR',
    value: StateAbbreviation.Or,
  },
  {
    label: 'PA',
    value: StateAbbreviation.Pa,
  },
  {
    label: 'RI',
    value: StateAbbreviation.Ri,
  },
  {
    label: 'SC',
    value: StateAbbreviation.Sc,
  },
  {
    label: 'SD',
    value: StateAbbreviation.Sd,
  },
  {
    label: 'TN',
    value: StateAbbreviation.Tn,
  },
  {
    label: 'TX',
    value: StateAbbreviation.Tx,
  },
  {
    label: 'UT',
    value: StateAbbreviation.Ut,
  },
  {
    label: 'VT',
    value: StateAbbreviation.Vt,
  },
  {
    label: 'VA',
    value: StateAbbreviation.Va,
  },
  {
    label: 'WA',
    value: StateAbbreviation.Wa,
  },
  {
    label: 'WV',
    value: StateAbbreviation.Wv,
  },
  {
    label: 'WI',
    value: StateAbbreviation.Wi,
  },
  {
    label: 'WY',
    value: StateAbbreviation.Wy,
  },
];

export const timeZonesOptions: SelectOptionElement[] = tz
  .zonesForCountry('US', true)
  .map((mzo) => ({ value: mzo.name, label: `${mzo.name} (UTC-${mzo.offset / 60})`, offset: mzo.offset }))
  .sort((a, b) => a.offset - b.offset);

export const paginationSizePerPage = 25;
export const smallTablePaginationSizePerPage = 10;

export const textFieldInputMaxLengthDefault = 150;

export const CHANGE_ASSIGNMENT_TO_NEW_JOBSITE_RADIO_TEXT = 'Change this assignment to a new jobsite';

export const DELETE_JOBSITE_ASSIGNMENT_RADIO_TEXT = 'Delete this jobsite assignment and all associated data';

export const CHANGE_JOBSITE_ASSIGNMENT_BANNER_TEXT = `This is a retroactive change from the beginning of 
the jobsite assignment and all historical records will be updated to reflect the new 
jobsite and contractor assignment.`;

export const DELETE_JOBSITE_ASSIGNMENT_BANNER_TEXT = `Removing this jobsite assignment without reassigning the data to 
another jobsite will result in all documents and other historical records associated with the 
original jobsite to be deleted. You cannot undo this action.`;
