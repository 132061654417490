import React, { ReactElement } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Avatar, getFaIcon } from '@odin-labs/components';
import { SearchBoxWrapper } from 'components/searchBox';
import { AuthContext } from 'auth';
import { to } from 'acl';
import { getInitialsForUser } from 'utils';
import { faCog } from 'components/icons';
import { navbarStyles } from './NavbarContainer.style';

const SettingsIcon = getFaIcon({ icon: faCog, className: navbarStyles.settingsIcon });

export const classes = {
  workerLink: cn(
    'focus:odin-outline-none focus:odin-ring-offset-2 focus:odin-shadow-odin-focus-ring focus:odin-rounded-full',
  ),
  settingsLink: cn(
    'odin-px-1.5 odin-py-1',
    'focus:odin-outline-none focus:odin-ring-offset-2 focus:odin-shadow-odin-focus-ring focus:odin-rounded-full',
  ),
};

export function DefaultNavbarContent(): ReactElement {
  const { currentUser: user } = React.useContext(AuthContext);
  return (
    <>
      {user.isAllowed(to.searchForWorker) ? <SearchBoxWrapper /> : <div />}
      <div className={navbarStyles.actionsContainer}>
        <Link to={`/worker/${user?.worker.workerId}`} className={classes.workerLink}>
          <Avatar
            size="sm"
            placeholder={getInitialsForUser(user)}
            objectFit="cover"
            src={user?.profilePictureCropped?.downloadUrl}
          />
        </Link>
        <Link to="/settings" className={classes.settingsLink}>
          <SettingsIcon />
        </Link>
      </div>
    </>
  );
}
