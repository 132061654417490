import React, { ReactElement, useState } from 'react';
import { useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { AlertService } from 'components/alertService';
import { TrashIcon } from 'components/icons';
import { Modal } from '@odin-labs/components';
import { useArchiveJobsiteWorkerAccessEventMutation } from 'apollo/generated/client-operations';
import { ArchiveJobsiteAccessEventModalProps } from './types';

export function ArchiveJobsiteAccessEventModal(props: ArchiveJobsiteAccessEventModalProps): ReactElement {
  const { jobsiteWorkerCheckInId, accessEventId, isOpen, closeModal: onCancel, onConfirm } = props;
  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [archiveJobsiteWorkerAccessEvent] = useArchiveJobsiteWorkerAccessEventMutation();

  const onAction = async (): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      await archiveJobsiteWorkerAccessEvent({ variables: { input: { accessEventId, jobsiteWorkerCheckInId } } });
      AlertService.alert('success', 'Success', 'A jobsite access event was successfully archived.');

      // delay the refresh with 2 seconds as the swipeEvents deletion
      // is done asynchronously within the pubsub process
      setTimeout(() => {
        if (isMounted()) setIsSaving(false);
        onConfirm();
      }, 2000);
    } catch (error) {
      if (isMounted()) setIsSaving(false);
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    }
  };

  return (
    <Modal
      open={isOpen}
      setOpen={onCancel}
      onAction={onAction}
      size="xs"
      title="Archive this Access Event?"
      titleAlignment="center"
      text={
        'Archiving this event will remove it from all timecard calculations and cannot be undone. ' +
        'Are you sure you want to archive this event?'
      }
      textAlignment="center"
      actionText="Archive Event"
      actionButtonTheme="danger"
      actionIcon={TrashIcon}
      actionButtonWithSpinner={isSaving}
    />
  );
}
