import React from 'react';
import { ApolloError } from '@apollo/client';
import { SelectOptionElement } from '@odin-labs/components';
import {
  UserAssignmentType,
  UserRoleKey,
  useGetUserRoleModalContractorsQuery,
  useGetUserRoleModalJobsitesQuery,
  useGetDevelopersQuery,
} from 'apollo/generated/client-operations';
import { ContractorForOption, ContractorOptionElement, getContractorsOptions } from 'containers/contractor/helpers';
import { AuthContext } from 'auth';
import { DeveloperForOption, getDevelopersOptions } from 'containers/jobsites/helpers/utils';
import { AddUserRoleModalProps, JobsiteForOption } from './types';
import { getJobsitesOptions, getUserRolesOptions } from './utils';

type UserModalOptions = {
  userRolesOptions: SelectOptionElement<UserRoleKey>[];
  contractorsOptions: ContractorOptionElement[];
  developersOptions: SelectOptionElement[];
  jobsitesOptions: SelectOptionElement[];
  error: ApolloError;
  loading: boolean;
};

export function useUserModalOptions(
  args: Pick<AddUserRoleModalProps, 'userRoleTypes' | 'userRoles'> & {
    editType: 'create' | 'update';
    jobsite?: JobsiteForOption;
    contractor?: ContractorForOption;
    developer?: DeveloperForOption;
    workerId?: string;
    assignedObjectIds?: AddUserRoleModalProps['assignedObjectIds'];
    includeAssignedObjects?: boolean;
  },
): UserModalOptions {
  const {
    userRoleTypes,
    userRoles,
    jobsite,
    contractor,
    developer,
    workerId,
    assignedObjectIds,
    includeAssignedObjects,
  } = args;
  const { currentUser: user } = React.useContext(AuthContext);

  const {
    data: workerJobsitesData,
    error: workerJobsitesError,
    loading: workerJobsitesLoading,
  } = useGetUserRoleModalJobsitesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      workerId: workerId ?? user.worker.workerId,
      availableRoleObjectsInput: { includeAssignedObjects },
    },
    skip:
      userRoleTypes === undefined ||
      (!!userRoleTypes && !userRoleTypes.includes(UserAssignmentType.Jobsite)) ||
      !!jobsite,
  });

  const {
    data: workerContractorsData,
    error: workerContractorsError,
    loading: workerContractorsLoading,
  } = useGetUserRoleModalContractorsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      workerId: workerId ?? user.worker.workerId,
      availableRoleObjectsInput: { includeAssignedObjects },
    },
    skip:
      userRoleTypes === undefined ||
      (!!userRoleTypes && !userRoleTypes.includes(UserAssignmentType.Contractor)) ||
      !!contractor,
  });

  const {
    data: developersData,
    error: developersError,
    loading: developersLoading,
  } = useGetDevelopersQuery({
    fetchPolicy: 'no-cache',
    skip:
      userRoleTypes === undefined ||
      (!!userRoleTypes && !userRoleTypes.includes(UserAssignmentType.Developer)) ||
      !!developer,
  });

  const error = workerJobsitesError ?? workerContractorsError ?? developersError;
  const loading = workerJobsitesLoading || workerContractorsLoading || developersLoading;

  return React.useMemo<UserModalOptions>(() => {
    const { jobsites: availableJobsites } = workerJobsitesData?.getWorker.user.availableRoleObjects ?? {};
    const { contractors: availableContractors } = workerContractorsData?.getWorker.user.availableRoleObjects ?? {};
    const availableDevelopers = developersData?.getCurrentSession.user.developers.edges.map(({ node }) => node);

    const jobsites: JobsiteForOption[] = jobsite ? [jobsite] : availableJobsites;
    const contractors: ContractorForOption[] = contractor ? [contractor] : availableContractors;
    const developers: DeveloperForOption[] = developer ? [developer] : availableDevelopers;

    const unassignedJobsites =
      userRoleTypes?.includes(UserAssignmentType.Jobsite) && !jobsite && assignedObjectIds?.length
        ? jobsites?.filter((j) => !assignedObjectIds.includes(j.jobsiteId))
        : jobsites;

    const unassignedContractors =
      userRoleTypes?.includes(UserAssignmentType.Contractor) && !contractor && assignedObjectIds?.length
        ? contractors?.filter((c) => !assignedObjectIds.includes(c.contractorId))
        : contractors;

    const unassignedDevelopers =
      userRoleTypes?.includes(UserAssignmentType.Developer) && !developer && assignedObjectIds?.length
        ? developers?.filter((d) => !assignedObjectIds.includes(d.developerId))
        : developers;

    return {
      userRolesOptions: getUserRolesOptions({ userRoles, userRoleTypes, showAdminRoles: user.isAdmin }),
      jobsitesOptions: getJobsitesOptions(unassignedJobsites),
      contractorsOptions: getContractorsOptions(unassignedContractors),
      developersOptions: getDevelopersOptions(unassignedDevelopers),
      error,
      loading,
    };
  }, [
    workerJobsitesData,
    workerContractorsData,
    userRoles,
    JSON.stringify(userRoleTypes),
    user,
    jobsite,
    contractor,
    assignedObjectIds,
    error,
    loading,
  ]);
}
