import pathToRegexp, { PathFunction } from 'react-router/node_modules/path-to-regexp';

const cache: Record<string, PathFunction> = {};
const cacheLimit = 10000;
let cacheCount = 0;

const compilePath = (path: string): PathFunction => {
  if (cache[path]) return cache[path];

  const generator = pathToRegexp.compile(path);

  if (cacheCount < cacheLimit) {
    cache[path] = generator;
    cacheCount += cacheCount + 1;
  }

  return generator;
};

/**
 * Public API for generating a URL pathname from a path and parameters.
 */
export const generatePath = (path = '/', params = {}): string => {
  let returnedPath = path;
  if (path === '/') {
    return returnedPath;
  }
  try {
    returnedPath = compilePath(path)(params);
  } catch (e) {
    returnedPath = '';
  }
  return returnedPath;
};
