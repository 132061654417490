import React, { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button, Table } from '@odin-labs/components';
import { useMutation } from '@apollo/client';
import {
  GetWorkerDataDocument,
  UserPendingChangeType,
  MutationUpdateWorkerArgs,
  MutationUploadSingleFileArgs,
  SelfOnboardingStepKey,
} from 'apollo/generated/client-operations';
import { InputFile, ServerFile } from 'types';
import { DebugIcon, EyeIcon, EyeSlashIcon } from 'components/icons';
import { Card, CardItem, CardFooter, CardHeader, CardHeaderTitle, CardBody, CardTitle } from 'components/reactstrap';
import { AlertService } from 'components/alertService';
import { Dropzone } from 'components/dropzone';
import { JobsiteWorkerSiteSafetyStatus } from 'components/jobsiteWorkerCard/jobsiteWorkerSiteSafetyStatus';
import { JobsiteWorkerAccessComponent } from 'components/jobsiteWorkerCard/jobsiteWorkerAccessComponent';
import { LoadingError } from 'components/loadingError';
import {
  CancelUserChangeModal,
  EditPersonalInfoModal,
  EditSelfOnboardingWorkerWarningModal,
  RemoveWorkerProfilePhotoModal,
  SubmitUserChangeModal,
} from 'containers/worker/modals';
import { JsonTreeModal } from 'components/modals/JsonTreeModal';
import { ObjectHistory } from 'components/objectHistory';
import { ObjectHistorySource } from 'components/objectHistory/types';
import { JobsiteWorkerOnboardingStatus } from 'components/jobsiteWorkerCard/jobsiteWorkerOnboardingStatus';
import { FILE_UPLOAD, UPDATE_WORKER } from 'containers/workerOnboarding/helpers/queries';
import { UploadFileResponse } from 'containers/workerOnboarding/types';
import { citizenshipStatusOptions } from 'containers/worker/helpers/utils';
import { WorkerTabProps } from 'containers/worker/types';
import {
  getFormattedPhoneNumber,
  getWorkerFullName,
  isServerFile,
  uploadCompressedFile,
  useBoolean,
  useIsMounted,
  useModalState,
  useResettableState,
} from 'utils';
import { convertUTCtoLocalDate, getISODateTimeToFormattedDate } from 'utils/dates';
import { getGraphQLError } from 'utils/error';
import { AuthContext } from 'auth';
import { to } from 'acl';
import workerImage from 'images/worker.png';
import { CancelUserChangeAction, SubmitUserChangeAction } from 'containers/worker/components';
import { WorkerOverviewBasic } from './WorkerOverviewBasic';
import { getCommentsColumns } from './WorkerOverview.tables';

export function WorkerOverview(props: WorkerTabProps): ReactElement {
  const { loading, worker, refetchWorkerData, jobsiteWorkers, jobsiteIds, isLocked, onTabApiChange } = props;
  const initialProfilePictureFile = worker?.profilePictureCropped;

  const { phoneNumber, email, pendingChanges } = worker?.user.identity ?? {};
  const isPhoneEditable = !phoneNumber || worker?.editableFields.phone;
  const isEmailEditable = !email || worker?.editableFields.email;

  const workerAddress = worker?.addressLine1 && (
    <>
      {[worker?.addressLine1, worker?.addressLine2].filter(Boolean).join(', ')}
      <br />
      {[worker?.addressCity, worker?.addressState, worker?.addressZipCode].filter(Boolean).join(', ')}
    </>
  );

  const { selfOnboardingCurrentStep } = worker ?? {};
  const isSelfOnboardingWorker =
    selfOnboardingCurrentStep &&
    selfOnboardingCurrentStep !== SelfOnboardingStepKey.ReviewCompletedProfile &&
    selfOnboardingCurrentStep !== SelfOnboardingStepKey.Closed;

  const isMounted = useIsMounted();

  const {
    value: isEditPersonalInfoModalOpen,
    setTrue: openEditPersonalInfoModal,
    setFalse: closeEditPersonalInfoModal,
  } = useBoolean(false);

  const {
    value: userChangeToSubmit,
    isModalOpen: isSubmitUserChangeModalOpen,
    openModal: openSubmitUserChangeModal,
    closeModal: closeSubmitUserChangeModal,
    resetModalValue: resetUserChangeToSubmit,
  } = useModalState<{ type: UserPendingChangeType }>(null);

  const {
    value: userChangeToCancel,
    isModalOpen: isCancelUserChangeModalOpen,
    openModal: openCancelUserChangeModal,
    closeModal: closeCancelUserChangeModal,
    resetModalValue: resetUserChangeToCancel,
  } = useModalState<{ type: UserPendingChangeType }>(null);

  const {
    value: { then: onEditSelfOnboardingWorkerConfirm },
    setValue: confirmSelfOnboardingWorkerEdit,
    resetValue: closeEditSelfOnboardingWorkerWarningModal,
  } = useResettableState<{ then: () => void }>({ then: null }, { then: null });

  const confirmSelfOnboardingWorkerEditIfNeeded = ({ then }: { then: () => void }): void => {
    if (isSelfOnboardingWorker) {
      confirmSelfOnboardingWorkerEdit({ then });
    } else {
      then();
    }
  };

  const { value: isSsnVisible, toggle: toggleSsn } = useBoolean(false);
  const { value: isWorkerIdsModalOpen, setTrue: openWorkerIdsModal, setFalse: closeWorkerIdsModal } = useBoolean(false);
  const [profilePictureFile, setProfilePictureFile] = useState<File | ServerFile>(initialProfilePictureFile);
  const [isFetchingUpdateWorker, setIsFetchingUpdateWorker] = useState<boolean>(false);
  const {
    value: isUploadProfilePictureModalOpen,
    setTrue: openUploadProfilePictureModal,
    setFalse: closeUploadProfilePictureModal,
  } = useBoolean(false);

  const {
    value: isRemoveProfilePictureModalOpen,
    setTrue: openRemoveProfilePictureModal,
    setFalse: closeRemoveProfilePictureModal,
  } = useBoolean(false);

  // UploadProfilePictureModal doesn't unmount when closed,
  // so we need to reset profilePictureFile when the modal is canceled
  // if the profilePictureFile was altered by user
  const onUploadProfilePictureModalCancel = React.useCallback(() => {
    if (profilePictureFile !== initialProfilePictureFile) {
      setProfilePictureFile(initialProfilePictureFile);
    }
    closeUploadProfilePictureModal();
  }, [initialProfilePictureFile, profilePictureFile, setProfilePictureFile, closeUploadProfilePictureModal]);

  const activeJobsiteData = jobsiteWorkers.filter((jw) => {
    return jw.endDate == null || new Date(jw.endDate) > new Date();
  });

  const { currentUser: user } = React.useContext(AuthContext);
  const isNotContractorMemberUser = !user.isContractor;

  const [uploadFile] = useMutation<UploadFileResponse, MutationUploadSingleFileArgs>(FILE_UPLOAD, {
    onError: (error) => {
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    },
  });

  const [updateWorker] = useMutation<Worker, MutationUpdateWorkerArgs>(UPDATE_WORKER, {
    onError: (error) => {
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    },
    refetchQueries: [GetWorkerDataDocument],
  });

  const workerComments = worker?.workerComments.edges
    ?.map(({ node }) => node)
    .filter((workerComment) => jobsiteIds.includes(workerComment?.jobsite?.jobsiteId));

  const activeJobsiteWorkerIds = activeJobsiteData.map((jw) => jw.jobsiteWorkerId);
  const activeJobsiteIds = activeJobsiteData.map((jw) => jw.jobsiteContractor?.jobsite?.jobsiteId);

  const idsToDisplay = {
    userAccountId: worker?.user?.userAccountId,
    workerId: worker?.workerId,
    fusionAuthId: worker?.user?.identity?.fusionAuthId,
    jobsiteWorkerIds: activeJobsiteWorkerIds,
    jobsiteIds: activeJobsiteIds,
    createdMethod: worker?.createdMethod,
    selfOnboardingCurrentStep: worker?.selfOnboardingCurrentStep,
  };

  React.useEffect(() => onTabApiChange({ refetchData: refetchWorkerData }), [refetchWorkerData]);

  const commentsColumns = React.useMemo(() => getCommentsColumns(), []);

  if (isLocked) {
    return <WorkerOverviewBasic worker={worker} />;
  }

  const workerCitizenshipStatus = citizenshipStatusOptions.find(
    (opt) => opt.value === worker?.citizenshipStatus,
  )?.label;

  const phoneNumberLink = phoneNumber && <a href={`tel:${phoneNumber}`}>{getFormattedPhoneNumber(phoneNumber)}</a>;
  const { emergencyContactPhone } = worker ?? {};
  const emergencyContactPhoneNumberLink = emergencyContactPhone && (
    <a href={`tel:${emergencyContactPhone}`}>{getFormattedPhoneNumber(emergencyContactPhone)}</a>
  );

  return (
    <>
      <div className="odin-flex odin-flex-col md:odin-flex-row odin-gap-6 odin-mb-6">
        <div className="md:odin-w-1/4 odin-flex odin-flex-col odin-gap-y-6">
          <Card className="odin-pt-6">
            <img
              className="odin-m-auto odin-w-1/2 odin-rounded-lg"
              src={worker?.profilePictureCropped?.downloadUrl || workerImage}
              alt="Worker"
            />
            <div className="odin-w-1/2 odin-m-auto odin-flex odin-justify-between odin-pt-2">
              <Button
                text="Clear"
                onClick={(): void => confirmSelfOnboardingWorkerEditIfNeeded({ then: openRemoveProfilePictureModal })}
                theme="naked"
                size="sm"
              />
              <Button
                text="Update"
                onClick={(): void => confirmSelfOnboardingWorkerEditIfNeeded({ then: openUploadProfilePictureModal })}
                theme="naked"
                size="sm"
              />
            </div>
            <CardBody className="odin-text-center">
              <CardTitle as="h2">{getWorkerFullName(worker)}</CardTitle>
              <p className="odin-text-1.5xs odin-text-gray-700">{worker?.trade}</p>
            </CardBody>
            <Modal
              open={isUploadProfilePictureModalOpen}
              setOpen={onUploadProfilePictureModalCancel}
              onCancel={onUploadProfilePictureModalCancel}
              actionButtonEnabled={!isFetchingUpdateWorker}
              onAction={async (): Promise<void> => {
                setIsFetchingUpdateWorker(true);
                if (!isServerFile(profilePictureFile)) {
                  const fileId = profilePictureFile
                    ? await uploadCompressedFile(profilePictureFile as File, uploadFile)
                    : null;
                  await updateWorker({
                    variables: {
                      workerId: worker.workerId,
                      workerInput: {
                        profilePictureFileId: fileId,
                      },
                    },
                  });
                }
                if (isMounted()) {
                  setIsFetchingUpdateWorker(false);
                  closeUploadProfilePictureModal();
                }
              }}
            >
              {isFetchingUpdateWorker ? (
                <LoadingError loading />
              ) : (
                <>
                  <h2>Update profile photo</h2>
                  <Dropzone
                    name="profilePicture"
                    label="Profile Picture"
                    accept={{ 'image/*': ['.png', '.jpg', '.jpeg'] }}
                    value={profilePictureFile}
                    onChange={(file: InputFile): void => {
                      setProfilePictureFile(file);
                    }}
                  />
                </>
              )}
            </Modal>
            <RemoveWorkerProfilePhotoModal
              isOpen={isRemoveProfilePictureModalOpen}
              closeModal={closeRemoveProfilePictureModal}
              onConfirm={(): void => {
                setProfilePictureFile(null);
                closeRemoveProfilePictureModal();
              }}
              workerId={worker.workerId}
            />
          </Card>
          {activeJobsiteData.length ? (
            <>
              <p className="odin-text-1.5xs odin-text-[#95AAC9]">Active Jobsite Assignments</p>
              {activeJobsiteData.map((jobsiteWorker) => {
                return (
                  <Card className="odin-p-6" key={jobsiteWorker?.jobsiteWorkerId}>
                    <div>
                      <h2 className="odin-text-xl odin-font-medium">
                        {jobsiteWorker?.jobsiteContractor?.jobsite?.name}
                      </h2>
                      <p className="odin-text-1.5sm">
                        {jobsiteWorker?.jobsiteContractor?.contractor?.organization?.name}
                      </p>
                      <JobsiteWorkerAccessComponent jobsiteWorker={jobsiteWorker} type="badge" />
                      <div className="odin-flex odin-justify-between">
                        <p className="odin-text-1.5sm odin-font-semibold">Onboarding Status</p>
                        <JobsiteWorkerOnboardingStatus jobsiteWorker={jobsiteWorker} showLabel={false} />
                      </div>
                      <div className="odin-flex odin-justify-between">
                        <p className="odin-text-1.5sm odin-font-semibold">Site Orientation Status</p>
                        <JobsiteWorkerSiteSafetyStatus jobsiteWorker={jobsiteWorker} showLabel={false} />
                      </div>
                    </div>
                  </Card>
                );
              })}
            </>
          ) : null}
        </div>
        <div className="md:odin-w-3/4 odin-flex odin-flex-col odin-gap-y-6">
          <Card>
            <CardHeader>
              <CardHeaderTitle as="h4" className="odin-mr-auto">
                Personal
              </CardHeaderTitle>
              {user.isAllowed(to.editWorkerPersonalInfo) && (
                <Button
                  onClick={(): void => confirmSelfOnboardingWorkerEditIfNeeded({ then: openEditPersonalInfoModal })}
                  text="Edit"
                  size={['base', 'md:sm']}
                />
              )}
            </CardHeader>
            <JsonTreeModal
              title={`IDs for ${worker?.user?.identity?.firstName} ${worker?.user?.identity?.lastName}`}
              isOpen={isWorkerIdsModalOpen}
              closeModal={closeWorkerIdsModal}
              data={idsToDisplay}
            />
            <div className="md:odin-flex odin-p-6">
              <div className="md:odin-w-1/2 odin-flex odin-flex-col odin-gap-y-3">
                <CardItem
                  label="Date of Birth"
                  value={getISODateTimeToFormattedDate(convertUTCtoLocalDate(worker?.birthDate))}
                />
                <CardItem
                  label={
                    <>
                      <span>Last 4 of SSN</span>
                      <span
                        className="odin-ml-2 odin-text-odin-primary odin-cursor-pointer print:odin-hidden"
                        onClick={toggleSsn}
                      >
                        {isSsnVisible ? <EyeIcon /> : <EyeSlashIcon />}
                      </span>
                    </>
                  }
                  value={isSsnVisible ? worker?.ssnLastFour : null}
                />
                <CardItem label="Race" value={worker?.race} />
                <CardItem label="Gender" value={worker?.gender} />
                <CardItem label="Primary Language" value={worker?.primaryLanguage} />
                <CardItem label="Citizenship Status" value={workerCitizenshipStatus} />
                <CardItem label="Veteran Status" value={worker?.isVeteran ? 'Yes' : 'No'} />
                <CardItem label="Trade" value={worker?.trade} />
                <CardItem label="Job Title" value={worker?.jobTitle} />
                <CardItem label="Union affiliation" value={worker?.unionAffiliation} />
              </div>
              <div className="md:odin-w-1/2 odin-flex odin-flex-col odin-gap-y-3">
                <CardItem label="Address" value={workerAddress} />
                <CardItem
                  label="Phone"
                  value={
                    <div className="odin-flex odin-gap-y-1 odin-justify-between odin-flex-wrap">
                      <span className="odin-w-5/6">{phoneNumberLink}</span>
                      <div className="odin-flex odin-gap-x-3">
                        <SubmitUserChangeAction
                          type={UserPendingChangeType.Phone}
                          hasPendingChange={!!pendingChanges?.phoneNumber}
                          isHidden={isPhoneEditable}
                          onClick={openSubmitUserChangeModal}
                        />
                        <CancelUserChangeAction
                          type={UserPendingChangeType.Phone}
                          isHidden={!pendingChanges?.phoneNumber}
                          onClick={openCancelUserChangeModal}
                        />
                      </div>
                    </div>
                  }
                />
                <CardItem
                  label="Email address"
                  value={
                    <div className="odin-flex odin-gap-y-1 odin-justify-between odin-flex-wrap">
                      <span className="odin-w-5/6">{email}</span>
                      <div className="odin-flex odin-gap-x-3">
                        <SubmitUserChangeAction
                          type={UserPendingChangeType.Email}
                          hasPendingChange={!!pendingChanges?.email}
                          isHidden={isEmailEditable}
                          onClick={openSubmitUserChangeModal}
                        />
                        <CancelUserChangeAction
                          type={UserPendingChangeType.Email}
                          isHidden={!pendingChanges?.email}
                          onClick={openCancelUserChangeModal}
                        />
                      </div>
                    </div>
                  }
                />
                <CardItem label="Emergency Contact" value={worker?.emergencyContactName} />
                <CardItem label="Emergency Relationship" value={worker?.emergencyContactRelationship} />
                <CardItem label="Emergency Phone" value={emergencyContactPhoneNumberLink} />
                <CardItem
                  label={
                    <div>
                      {user.isAllowed(to.viewWorkerIDs) && (
                        <a
                          href=""
                          onClick={(e): void => {
                            e.preventDefault();
                            openWorkerIdsModal();
                          }}
                          className="odin-underline"
                        >
                          <DebugIcon className="odin-mr-3 odin-text-base" />
                          View worker IDs
                        </a>
                      )}
                    </div>
                  }
                  value={null}
                />
              </div>
            </div>
            <CardFooter>
              <ObjectHistory className="odin-text-1.5sm" source={ObjectHistorySource.Worker} object={worker} />
            </CardFooter>
          </Card>
          {jobsiteWorkers.length && isNotContractorMemberUser ? (
            <Card>
              <CardHeader className="!odin-border-b-0">
                <CardHeaderTitle as="h4" className="odin-mr-auto">
                  Comments
                </CardHeaderTitle>
              </CardHeader>
              <div className="odin-m-0 odin-p-0">
                <Table
                  loading={loading}
                  columns={commentsColumns}
                  data={workerComments}
                  remote
                  cellClassName="!odin-pl-5"
                  disableGlobalFilter
                  disableSortBy
                />
              </div>
              {workerComments?.length ? (
                <CardFooter className="!odin-border-t-0">
                  <Link to={`/worker/${worker?.workerId}/comments`}>See all comments</Link>
                </CardFooter>
              ) : null}
            </Card>
          ) : null}
        </div>
      </div>
      <EditPersonalInfoModal
        worker={worker}
        isOpen={isEditPersonalInfoModalOpen}
        onCancel={closeEditPersonalInfoModal}
        refetch={refetchWorkerData}
      />
      <EditSelfOnboardingWorkerWarningModal
        isOpen={!!onEditSelfOnboardingWorkerConfirm}
        closeModal={closeEditSelfOnboardingWorkerWarningModal}
        onConfirm={(): void => {
          onEditSelfOnboardingWorkerConfirm();
          closeEditSelfOnboardingWorkerWarningModal();
        }}
      />
      <SubmitUserChangeModal
        isOpen={isSubmitUserChangeModalOpen}
        onCancel={closeSubmitUserChangeModal}
        onClosed={resetUserChangeToSubmit}
        onConfirm={closeSubmitUserChangeModal}
        type={userChangeToSubmit?.type}
        worker={worker}
        refetch={refetchWorkerData}
      />
      <CancelUserChangeModal
        isOpen={isCancelUserChangeModalOpen}
        onCancel={closeCancelUserChangeModal}
        onClosed={resetUserChangeToCancel}
        onConfirm={closeCancelUserChangeModal}
        type={userChangeToCancel?.type}
        worker={worker}
        refetch={refetchWorkerData}
      />
    </>
  );
}
