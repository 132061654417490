import React from 'react';
import cn from 'classnames';
import { Button, DropdownButton } from '@odin-labs/components';
import { useHistory } from 'react-router';
import { ArrowLeftIcon, CogIcon, FileExportIcon, HistoryIcon, PrintIcon, RedoIcon } from 'components/icons';
import { RoutedTabsHeader } from 'components/tabs';
import { NewHeaderActionsProps } from './types';
import { NewHeaderButton } from './NewHeaderButton';

export function NewHeaderActions(props: NewHeaderActionsProps): React.ReactElement {
  const { children, menuItems, baseRoute, headerActions = ['reload'], onReloadPressed, tabsProps } = props;
  const { tabs, currentTab } = tabsProps ?? {};

  const history = useHistory();

  const handleBackClick = React.useCallback((): void => {
    history.goBack();
  }, []);

  const handleReloadClick = React.useCallback((): void => {
    const reload = onReloadPressed ?? window.location.reload.bind(window.location);
    reload();
  }, [onReloadPressed]);

  const handlePrintClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      if (event.metaKey || event.ctrlKey) {
        window.open(`${baseRoute}/print`, '_blank');
      } else {
        history.push(`${baseRoute}/print`);
      }
    },
    [history],
  );

  const handleExportClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      if (event.metaKey || event.ctrlKey) {
        window.open(`${baseRoute}/print`, '_blank');
      } else {
        history.push(`${baseRoute}/print`);
      }
    },
    [history],
  );

  const handleSettingsClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      if (event.metaKey || event.ctrlKey) {
        window.open(`${baseRoute}/settings`, '_blank');
      } else {
        history.push(`${baseRoute}/settings`);
      }
    },
    [history],
  );

  const handleHistoryClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      if (event.metaKey || event.ctrlKey) {
        window.open(`/entity-change${baseRoute}`, '_blank');
      } else {
        history.push(`/entity-change${baseRoute}`);
      }
    },
    [history],
  );

  const backButton = headerActions?.includes('back') && (
    <Button text="Back" icon={ArrowLeftIcon} theme="naked" onClick={handleBackClick} />
  );

  const reloadButton = headerActions?.includes('reload') && (
    <NewHeaderButton icon={RedoIcon} onClick={handleReloadClick} />
  );

  const printButton = headerActions?.includes('print') && (
    <NewHeaderButton icon={PrintIcon} onClick={handlePrintClick} />
  );

  const exportButton = headerActions?.includes('export') && (
    <Button text="Export report" hideTextOnMobile icon={FileExportIcon} theme="white" onClick={handleExportClick} />
  );

  const settingsButton = headerActions?.includes('settings') && (
    <NewHeaderButton icon={CogIcon} onClick={handleSettingsClick} />
  );

  const historyButton = headerActions?.includes('history') && (
    <NewHeaderButton icon={HistoryIcon} onClick={handleHistoryClick} />
  );

  const getActionsButton = (): React.ReactElement | undefined => {
    if (menuItems?.length) {
      const [firstMenuItem] = menuItems;
      if (menuItems.length === 1) {
        return <Button hideTextOnMobile={firstMenuItem.hideTextOnMobile ?? true} {...firstMenuItem} />;
      }
      return <DropdownButton menuItems={menuItems} icon={firstMenuItem.icon} text={firstMenuItem.text} />;
    }
    return undefined;
  };

  return (
    <div
      className={cn(!backButton && 'odin-ml-auto', 'odin-flex odin-justify-between odin-items-center odin-shrink-0')}
    >
      {backButton}
      <div className="odin-flex odin-items-center">
        {reloadButton}
        {printButton}
        {exportButton}
        {historyButton}
        {settingsButton}
        {(menuItems?.length || children) && (
          <div className="odin-ml-5">
            {getActionsButton()}
            {children}
          </div>
        )}
        {tabs ? (
          <div className="odin-ml-3">
            <RoutedTabsHeader
              tabs={tabs}
              currentTab={currentTab}
              headerStyle="buttonGroup"
              headerAlignment="right"
              hideText
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
