import { appConfig } from 'config/app';
import queryString from 'query-string';

import type { LookerReport, LookerFilterSpec } from './types';

export const openLookerReportDialog = (report: LookerReport, filters: LookerFilterSpec): void => {
  const url = `${appConfig.lookerDashboardUrl}?report=${report}&${new URLSearchParams(filters).toString()}`;
  window.open(url, '_blank');
};

export const openLookerReports = (report: LookerReport, filters: LookerFilterSpec, target: string): void => {
  const urlParams = { report, ...filters };
  const urlParamsText = queryString.stringify(urlParams, { skipEmptyString: true, skipNull: true });
  const url = [appConfig.lookerDashboardUrl, urlParamsText].filter(Boolean).join('?');
  if (target) {
    window.open(url, target);
  } else {
    window.location.replace(url);
  }
};

export const getLookerReportUrl = (report: LookerReport, filters: LookerFilterSpec): string => {
  return `${appConfig.lookerDashboardUrl}?report=${report}&${queryString.stringify(filters, {
    skipEmptyString: true,
    skipNull: true,
  })}`;
};
