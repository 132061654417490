import React from 'react';
import { Link } from 'react-router-dom';
import { useJobsitesSelection } from 'graphql/client/useJobsitesSelection';
import { openLookerReports } from 'looker';
import { LookerFilter, LookerReport } from 'looker/types';
import { CustomLinkProps } from './types';

export function ReportsLink(props: CustomLinkProps): React.ReactElement {
  const { target } = props;
  const [isClicked, setIsClicked] = React.useState(false);
  const { loading, selectedJobsites, areAllJobsitesSelected } = useJobsitesSelection();

  React.useEffect(() => {
    if (isClicked && !loading) {
      setIsClicked(false);
      const selectedJobsiteNames = areAllJobsitesSelected ? '' : selectedJobsites?.map(({ name }) => name).join() ?? '';
      openLookerReports(LookerReport.Dashboard, { [LookerFilter.Jobsite]: selectedJobsiteNames }, target);
    }
  }, [isClicked, loading, selectedJobsites, areAllJobsitesSelected]);

  return (
    <Link
      {...props}
      onClick={(e): void => {
        e.preventDefault();
        setIsClicked(true);
      }}
    />
  );
}
