import { ApolloClient, ApolloLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { generateCache } from 'apollo/cache';
import { resolvers } from 'apollo/resolvers';
import { typeDefs } from 'apollo/schema';
import { ApolloState } from 'apollo/types';
import { LocalStorageWrapper, persistCache } from 'apollo3-cache-persist';
import { FusionAuthJwtPayload } from 'auth/types';
import { appConfig } from 'config/app';
import jwtDecode from 'jwt-decode';
import { isPreAuthRoute } from 'routes';
import { captureError } from 'utils';
import { setJwtCookie } from 'utils/cookies';

export type GetApolloClientArgs = {
  getAccessToken: () => string;
  refreshAccessToken: () => Promise<string>;
  signOut: () => void;
  initialState: ApolloState;
  graphQLUrl: string;
};

const handleAccessTokenError = (error: Error, extraMessage: string, signOut: () => void): void => {
  const { message: errorMessage } = error;
  const message = extraMessage + (errorMessage ? `: ${errorMessage}` : '');
  Object.assign(error, { message });
  captureError(error);
  signOut();
};

let pendingAccessTokenPromise: Promise<string> | null = null;

const resolveAccessToken = async (
  getAccessToken: () => string,
  refreshAccessToken: () => Promise<string>,
  signOut: () => void,
): Promise<string> => {
  const accessToken = getAccessToken();

  if (!accessToken && isPreAuthRoute(window.location.pathname)) {
    return Promise.resolve(undefined);
  }

  let jwt: FusionAuthJwtPayload;
  try {
    jwt = jwtDecode<FusionAuthJwtPayload>(accessToken);
  } catch (e) {
    handleAccessTokenError(e as Error, 'Unable to decode locally stored access token', signOut);
    return Promise.resolve(undefined);
  }

  const { exp } = jwt ?? {};
  // Subtract a minute to account for any latency
  const fuzzyExpireTime = exp * 1000 - 60000;
  // If the token is not expiring in the next minute
  if (exp === 0 || Date.now() <= fuzzyExpireTime) {
    return Promise.resolve(accessToken);
  }

  // This handles multiple concurrent requests (by using single promise for all requests)
  // The below promise is only holding those concurrent requests till new token is resolved.
  // https://github.com/earthguestg/React-GraphQL-JWT-Authentication-Example/blob/main/src/modAuth/utils.jsx
  if (!pendingAccessTokenPromise) {
    pendingAccessTokenPromise = refreshAccessToken()
      .catch((e): string => {
        handleAccessTokenError(e as Error, 'Unable to refresh user access token', signOut);
        return undefined;
      })
      .finally(() => {
        pendingAccessTokenPromise = null;
      });
  }

  return pendingAccessTokenPromise;
};

/**
 * Context link that manually sets the `Authorization` header with the current access token (JWT)
 * and also checks the expiration of the access token and requests a new one using the user's current
 * refresh token.
 *
 * @param getAccessToken - function that handles getting the current access token
 * @param refreshAccessToken - function that handles refreshing the expired access token
 */
const createJwtLink = (
  getAccessToken: () => string,
  refreshAccessToken: () => Promise<string>,
  signOut: () => void,
): ApolloLink =>
  setContext(async (_, link: any) => {
    const accessToken = await resolveAccessToken(getAccessToken, refreshAccessToken, signOut);
    if (accessToken) {
      setJwtCookie({ accessToken });
    }
    return link;
  });

export const getApolloClient = async ({
  refreshAccessToken,
  getAccessToken,
  signOut,
  initialState,
  graphQLUrl,
}: GetApolloClientArgs): Promise<ApolloClient<ApolloState>> => {
  const cache = generateCache(initialState);

  await persistCache({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
  });

  const jwtLink = createJwtLink(getAccessToken, refreshAccessToken, signOut);
  const uploadLink = createUploadLink({
    uri: graphQLUrl,
    credentials: 'include',
    headers: { 'Apollo-Require-Preflight': 'true' },
  }) as unknown as ApolloLink;
  const links = [jwtLink, uploadLink];

  return new ApolloClient({
    cache,
    link: from(links),
    typeDefs,
    resolvers,
    connectToDevTools: !appConfig.isProd,
  });
};
