import React, { MouseEventHandler } from 'react';
import cn from 'classnames';
import { getFaIcon } from '@odin-labs/components';

export const classes = {
  headerActionItemButton: (extraClasses: string): string =>
    cn(
      'odin-px-3.5 odin-py-3 odin-rounded-md odin-text-odin-primary',
      'focus:odin-outline-none focus:odin-ring-offset-2 focus:odin-shadow-odin-focus-ring', // 'focus:odin-ring-2 ',
      'hover:odin-bg-gray-200',
      extraClasses,
    ),
};

export interface NewHeaderButtonProps {
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  icon?: ReturnType<typeof getFaIcon>;
}

export function NewHeaderButton({
  children,
  className,
  icon: Icon,
  onClick,
}: React.PropsWithChildren<NewHeaderButtonProps>): React.ReactElement {
  return (
    <button className={classes.headerActionItemButton(className)} onClick={onClick}>
      <Icon className="odin-font-normal odin-text-sm" />
      {children}
    </button>
  );
}
