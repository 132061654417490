import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import moment, { MomentInput } from 'moment';
import { DeepMap } from 'react-hook-form';
import { SelectOptionElement, useDidUpdateEffect } from '@odin-labs/components';
import {
  FileChangeInput,
  JobsiteWorkerDocumentUpsertInput,
  ChangeType,
  useUpsertJobsiteWorkerDocumentMutation,
  UserIdentity,
  useExtractIdDataFromFileMutation,
  ExtractionProcessorType,
  IdCardData,
  AdditionalFieldValueInput,
} from 'apollo/generated/client-operations';
import { AcknowledgmentProgress, ServerFile } from 'types';
import {
  camelToKebabCase,
  ensureNonEmptyItems,
  ensureNonUndefinedFields,
  ensureStringEnumValue,
  getLocalIp,
  getUserFullName,
  isNotEmpty,
  tryFileCompression,
  usePrevious,
} from 'utils';
import { getPrettyFormattedUtcDate, momentFormatter } from 'utils/dates';
import { DocumentKey } from 'containers/worker/utils';

import {
  FormInputTypes,
  TypedFormInputs,
  GridColSpan,
  UseFormMethods,
  UseInputs,
  getUpdateInputValueFunction,
  OnBeforeChangeHandler,
} from 'components/form';
import {
  statesOptions,
  toFancySelectOptions,
  sstCardTypes as sstCardOptionsValues,
  govCardTypes as govCardOptionsValues,
  oshaCardTypes as oshaCardOptionsValues,
} from 'utils/constants';
import { dateValidation } from 'utils/validation';
import { dropzoneValidation } from 'components/dropzone/utils';
import { AlertService } from 'components/alertService';
import { getGraphQLError } from 'utils/error';
import { InputFile } from 'components/dropzone';
import { Copy } from 'containers/selfOnboarding/steps/utils';
import {
  JobsiteInvitation,
  FormInputArgs,
  SelfOnboardingDocumentFormData,
  AcknowledgmentOption,
  AcknowledgmentStatus,
  SelfOnboardingWorker,
  SelfOnboardingUser,
  SelfOnboardingState,
  AcknowledgmentFile,
  Localize,
} from 'containers/selfOnboarding/steps/types';
import { ClockIcon } from 'components/icons';
import { AcknowledgmentDocumentHeader } from './AcknowledgmentDocumentHeader';
import { AcknowledgmentDocumentFooter } from './AcknowledgmentDocumentFooter';

const govCardOptions = toFancySelectOptions(govCardOptionsValues);
const sstCardOptions = toFancySelectOptions(sstCardOptionsValues);
const oshaCardOptions = toFancySelectOptions(oshaCardOptionsValues);

export const stepDocumentKeys = [
  DocumentKey.WorkerConsentDocument,
  DocumentKey.GovernmentIssuedId,
  DocumentKey.NycSiteSafetyTrainingCard,
  DocumentKey.OshaCard,
  DocumentKey.JobsiteSafetyVideo,
  DocumentKey.JobsiteSafetyDocument,
  DocumentKey.LIRRBlueTraining,
  DocumentKey.ConfinedSpacesTraining,
] as const;

export type StepDocumentKey = typeof stepDocumentKeys[number];

export const resolveDocumentKey = (step: string): DocumentKey => {
  const stepDocumentKey = step as StepDocumentKey;
  return stepDocumentKeys.includes(stepDocumentKey) ? stepDocumentKey : null;
};

type UseDocumentArgs = {
  documentKey: DocumentKey;
  state: SelfOnboardingState;
  updateState: (data: Partial<SelfOnboardingState>) => void;
};

type UseDocumentReturn = {
  document: SelfOnboardingDocumentFormData;
  updateDocument: (newDocument: SelfOnboardingDocumentFormData) => void;
};

export function useDocument(args: UseDocumentArgs): UseDocumentReturn {
  const { documentKey, state, updateState } = args;
  const { documentTypes } = state;
  const acknowledgmentFile = documentTypes?.[documentKey]?.acknowledgmentFile;

  const document = React.useMemo(
    (): SelfOnboardingDocumentFormData =>
      state?.documents?.find((doc) => doc.key === documentKey) ?? {
        key: documentKey,
        versionId: '',
        acknowledgmentFile,
      },
    [state, documentKey, acknowledgmentFile],
  );

  const updateDocument = React.useCallback(
    (newDocument: SelfOnboardingDocumentFormData) => {
      const documents = [...(state?.documents ?? []).filter((doc) => doc.key !== documentKey), newDocument];
      updateState({ documents });
    },
    [updateState, state, documentKey],
  );

  return { document, updateDocument };
}

export const acknowledgementDocumentKeys = [
  DocumentKey.JobsiteSafetyVideo,
  DocumentKey.JobsiteSafetyDocument,
  DocumentKey.WorkerConsentDocument,
];
export const isAcknowledgmentDocument = (documentKey: DocumentKey): boolean =>
  acknowledgementDocumentKeys.includes(documentKey);

export type SelfOnboardingDocumentVisibleData = Required<
  Omit<SelfOnboardingDocumentFormData, 'key' | 'versionId' | 'acknowledgmentFile' | 'acknowledgmentDate'>
>;

export type DocumentInputConfig = {
  visibleKeys: (keyof SelfOnboardingDocumentVisibleData)[];
  typeOptions: SelectOptionElement[];
  typeCopy: Copy;
  numberCopy: Copy;
  titleCopy: Copy;
  subtitleCopy: Copy;
  missingCopy: Copy;
};

export const documentInputsConfig: Record<StepDocumentKey, DocumentInputConfig> = {
  [DocumentKey.WorkerConsentDocument]: {
    visibleKeys: ['acknowledgmentProgress'],
    typeOptions: [],
    typeCopy: null,
    numberCopy: null,
    titleCopy: Copy.self_onboarding_worker_consent_header,
    subtitleCopy: Copy.self_onboarding_worker_consent_instructions,
    missingCopy: null,
  },
  [DocumentKey.GovernmentIssuedId]: {
    visibleKeys: ['frontFile', 'backFile', 'type', 'stateIssued', 'number', 'issueDate', 'expirationDate'],
    typeOptions: govCardOptions,
    typeCopy: Copy.id_type_label,
    numberCopy: Copy.government_id_number_label,
    titleCopy: Copy.self_onboarding_government_issued_id_header,
    subtitleCopy: Copy.self_onboarding_government_issued_id_instructions,
    missingCopy: Copy.self_onboarding_no_government_id,
  },
  [DocumentKey.NycSiteSafetyTrainingCard]: {
    visibleKeys: ['frontFile', 'backFile', 'type', 'issueDate', 'expirationDate', 'isTrainingConnectCard'],
    typeOptions: sstCardOptions,
    typeCopy: Copy.card_type_label,
    numberCopy: null,
    titleCopy: Copy.self_onboarding_ny_sst_card_header,
    subtitleCopy: Copy.self_onboarding_ny_sst_card_instructions,
    missingCopy: Copy.self_onboarding_no_ny_sst_card,
  },
  [DocumentKey.OshaCard]: {
    visibleKeys: ['frontFile', 'backFile', 'type', 'issueDate', 'number'],
    typeOptions: oshaCardOptions,
    typeCopy: Copy.card_type_label,
    numberCopy: Copy.osha_card_number_label,
    titleCopy: Copy.self_onboarding_osha_card_header,
    subtitleCopy: Copy.self_onboarding_osha_card_instructions,
    missingCopy: Copy.self_onboarding_no_osha_card,
  },
  [DocumentKey.JobsiteSafetyVideo]: {
    visibleKeys: ['acknowledgmentProgress', 'acknowledgmentStatus'],
    typeOptions: [],
    typeCopy: null,
    numberCopy: null,
    titleCopy: Copy.self_onboarding_jobsite_safety_video_header,
    subtitleCopy: Copy.self_onboarding_jobsite_safety_video_instructions,
    missingCopy: Copy.self_onboarding_jobsite_safety_video_not_acknowledged,
  },
  [DocumentKey.JobsiteSafetyDocument]: {
    visibleKeys: ['acknowledgmentProgress', 'acknowledgmentStatus'],
    typeOptions: [],
    typeCopy: null,
    numberCopy: null,
    titleCopy: Copy.self_onboarding_jobsite_safety_document_header,
    subtitleCopy: Copy.self_onboarding_jobsite_safety_document_instructions,
    missingCopy: Copy.self_onboarding_jobsite_safety_document_not_acknowledged,
  },
  [DocumentKey.LIRRBlueTraining]: {
    visibleKeys: ['frontFile', 'expirationDate'],
    typeOptions: [],
    typeCopy: null,
    numberCopy: null,
    titleCopy: Copy.self_onboarding_lirr_blue_training_header,
    subtitleCopy: Copy.self_onboarding_lirr_blue_training_instructions,
    missingCopy: Copy.self_onboarding_no_lirr_blue_training,
  },
  [DocumentKey.ConfinedSpacesTraining]: {
    visibleKeys: ['frontFile', 'expirationDate'],
    typeOptions: [],
    typeCopy: null,
    numberCopy: null,
    titleCopy: Copy.self_onboarding_confined_spaces_training_header,
    subtitleCopy: Copy.self_onboarding_confined_spaces_training_instructions,
    missingCopy: Copy.self_onboarding_no_confined_spaces_training,
  },
};

export const getDocumentInputsConfig = (
  documentKey: DocumentKey,
): typeof documentInputsConfig[keyof typeof documentInputsConfig] =>
  documentInputsConfig[documentKey as StepDocumentKey];

const getVisibleInputs = (
  documentKey: StepDocumentKey,
  inputs: TypedFormInputs<SelfOnboardingDocumentVisibleData>,
): TypedFormInputs<SelfOnboardingDocumentFormData> => {
  const { visibleKeys } = getDocumentInputsConfig(documentKey);
  return Object.fromEntries(visibleKeys.map((inputName) => [inputName, inputs[inputName]]));
};

const getRemainingTime = ({ fraction, value }: AcknowledgmentProgress): string => {
  return new Date((1000 * (value * (1 - fraction))) / fraction).toISOString().slice(14, 19);
};

type GetAcknowledgmentOptions = {
  acknowledgmentDocType: 'video' | 'html';
  acknowledgmentProgress: AcknowledgmentProgress;
  isAcknowledgmentConfirmed: boolean;
  localize: Localize;
};

const getAcknowledgmentOptions = (options: GetAcknowledgmentOptions): AcknowledgmentOption[] => {
  const { acknowledgmentDocType, acknowledgmentProgress, isAcknowledgmentConfirmed, localize } = options;
  if (acknowledgmentDocType === 'html') {
    return [
      {
        value: AcknowledgmentStatus.Confirmed,
        label: localize(Copy.self_onboarding_acknowledgment_doc_confirmed_label),
        description: localize(Copy.self_onboarding_acknowledgment_doc_confirmed_description),
        disabled: isAcknowledgmentConfirmed,
      },
    ];
  }
  return ensureNonEmptyItems<AcknowledgmentOption>([
    !acknowledgmentProgress?.fraction && {
      value: AcknowledgmentStatus.NotStarted,
      label: localize(Copy.self_onboarding_acknowledgment_video_not_started_label),
      disabled: true,
      disabledDescription: localize(Copy.self_onboarding_acknowledgment_video_not_started_disabled_description),
    },
    acknowledgmentProgress?.fraction > 0 &&
      acknowledgmentProgress.fraction < 1 && {
        value: AcknowledgmentStatus.InProgress,
        label: localize(Copy.self_onboarding_acknowledgment_video_in_progress_label),
        disabled: true,
        disabledDescription: getRemainingTime(acknowledgmentProgress),
        disabledIcon: ClockIcon,
      },
    acknowledgmentProgress?.fraction === 1 && {
      value: AcknowledgmentStatus.Confirmed,
      label: localize(Copy.self_onboarding_acknowledgment_video_confirmed_label),
      description: localize(Copy.self_onboarding_acknowledgment_video_confirmed_description),
      disabled: isAcknowledgmentConfirmed,
    },
  ]);
};

export type GetFormInputsArgs = Pick<FormInputArgs, 'document'> & {
  onBeforeFrontFileChange: OnBeforeChangeHandler;
  acknowledgmentProgress: AcknowledgmentProgress;
  localize: Localize;
  loading: boolean;
};

const getFormInputs = (args: GetFormInputsArgs): TypedFormInputs<SelfOnboardingDocumentFormData> => {
  const { document, onBeforeFrontFileChange, acknowledgmentProgress, localize, loading } = args ?? {};
  const documentKey = document.key;
  const { typeOptions, typeCopy, numberCopy } = getDocumentInputsConfig(documentKey);
  const acknowledgmentDocType = documentKey === DocumentKey.JobsiteSafetyVideo ? 'video' : 'html';
  const isAcknowledgmentConfirmed = !!document.acknowledgmentDate;
  const acknowledgmentOptions = getAcknowledgmentOptions({
    acknowledgmentDocType,
    acknowledgmentProgress,
    isAcknowledgmentConfirmed,
    localize,
  });

  const inputs: TypedFormInputs<SelfOnboardingDocumentVisibleData> = {
    acknowledgmentProgress:
      acknowledgmentDocType === 'video'
        ? {
            element: FormInputTypes.Player,
            elementProps: {
              url: document.acknowledgmentFile?.downloadUrl,
            },
            validation: {
              required: true,
              validate: dropzoneValidation,
            },
            layout: [GridColSpan.SpanFull],
          }
        : {
            element: FormInputTypes.HtmlRenderer,
            elementProps: {
              url: document.acknowledgmentFile?.downloadUrl,
            },
            layout: [GridColSpan.SpanFull],
          },
    acknowledgmentStatus: {
      element: FormInputTypes.CheckList,
      elementProps: {
        options: acknowledgmentOptions,
      },
      layout: [GridColSpan.SpanFull, 'print:odin-hidden'],
    },
    frontFile: {
      element: FormInputTypes.Dropzone,
      label: 'Add Front',
      elementProps: {
        accept: {
          'application/pdf': [],
          'image/*': ['.png', '.jpg', '.jpeg', '.heic', '.heif'],
        },
        onBeforeChange: onBeforeFrontFileChange,
      },
      validation: {
        required: true,
        validate: dropzoneValidation,
      },
      layout: [GridColSpan.Span6],
      loading,
    },
    backFile: {
      element: FormInputTypes.Dropzone,
      label: 'Add Back',
      elementProps: {
        accept: {
          'application/pdf': [],
          'image/*': ['.png', '.jpg', '.jpeg', '.heic', '.heif'],
        },
      },
      validation: {
        validate: dropzoneValidation,
      },
      layout: [GridColSpan.Span6],
      loading,
    },
    type: {
      element: FormInputTypes.Select,
      label: 'Type',
      labelCopy: typeCopy,
      elementProps: {
        options: typeOptions,
      },
      validation: { required: true },
      layout: [GridColSpan.SpanFull],
      loading,
    },
    stateIssued: {
      element: FormInputTypes.Select,
      label: 'State Issued',
      elementProps: {
        placeholder: '',
        options: statesOptions,
      },
      validation: { required: true },
      layout: [GridColSpan.Span6],
      loading,
    },
    number: {
      element: FormInputTypes.Field,
      label: 'ID Number',
      labelCopy: numberCopy,
      validation: { required: true },
      layout: [GridColSpan.Span6],
      loading,
    },
    issueDate: {
      element: FormInputTypes.Field,
      label: 'Issue Date',
      elementProps: {
        placeholder: 'MM/DD/YYYY',
        fieldType: 'pastDate',
        inputMode: 'numeric',
      },
      validation: { required: true, pattern: dateValidation },
      layout: [GridColSpan.Span6],
      loading,
    },
    expirationDate: {
      element: FormInputTypes.Field,
      label: 'Expiration Date',
      elementProps: {
        placeholder: 'MM/DD/YYYY',
        fieldType: 'date',
        inputMode: 'numeric',
      },
      validation: { required: true, pattern: dateValidation },
      layout: [GridColSpan.Span6],
      loading,
    },
    isTrainingConnectCard: {
      element: FormInputTypes.Toggle,
      label: 'Training Connect Card',
      layout: [GridColSpan.Span6],
      loading,
    },
  };
  return getVisibleInputs(documentKey as StepDocumentKey, inputs);
};

const ACKNOWLEDGMENT_PROGRESS_SAVE_INTERVAL = 10;

const getExtractionProcessorType = (documentKey: DocumentKey): ExtractionProcessorType => {
  switch (documentKey) {
    case DocumentKey.GovernmentIssuedId:
      return ExtractionProcessorType.IdCard;
    case DocumentKey.NycSiteSafetyTrainingCard:
      return ExtractionProcessorType.SstCard;
    case DocumentKey.OshaCard:
      return ExtractionProcessorType.OshaCard;
    default:
      return null;
  }
};

function getOshaCardTypeByTitle(title: string): string {
  if (title) {
    /*
    'OSHA 10 hour construction',
    'OSHA 30 hour construction',
    'OSHA construction trainer card',
    'Disaster Site Trainer card',
    'Maritime Standards card',
    */
    if (title.toLowerCase().includes('10-hour')) return 'OSHA 10 hour construction';
    if (title.toLowerCase().includes('30-hour')) return 'OSHA 30 hour construction';
  }
  return null;
}

const getDocUpdateFromExtractedData = (extractedData: IdCardData): SelfOnboardingDocumentFormData => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { address, dateOfBirth, expirationDate, firstName, fullName, idType, identifier, issueDate, lastName, title } =
    extractedData;

  let type: SelectOptionElement;
  let stateIssued: SelectOptionElement;
  switch (idType) {
    case ExtractionProcessorType.IdCard:
      type = { label: 'Driver license', value: 'Driver license' };
      stateIssued = address && statesOptions.find((opt) => address.includes(` ${opt.value} `));
      break;
    case ExtractionProcessorType.OshaCard:
      type = oshaCardOptions.find((opt) => opt.value === getOshaCardTypeByTitle(title));
      stateIssued = address && statesOptions.find((opt) => address.includes(` ${opt.value} `));
      break;

    default:
      break;
  }

  let isTrainingConnectCard: boolean;
  if (idType === ExtractionProcessorType.SstCard && issueDate) {
    const issueMoment = moment(issueDate, 'YYYY-MM-DD', true);
    isTrainingConnectCard = issueMoment.isSameOrAfter('01/01/2022');
  }

  return ensureNonUndefinedFields<SelfOnboardingDocumentFormData>({
    type,
    stateIssued,
    number: identifier?.replace(/\s/g, ''),
    issueDate: (issueDate ?? undefined) && getPrettyFormattedUtcDate(issueDate),
    expirationDate: (expirationDate ?? undefined) && getPrettyFormattedUtcDate(expirationDate),
    isTrainingConnectCard,
  });
};

export const getFormInputsHook =
  (
    args: FormInputArgs & {
      localize: Localize;
      updateDocument: (newDocument: SelfOnboardingDocumentFormData) => void;
      setIsContinueActionEnabled: (value: boolean) => void;
    },
  ): UseInputs<SelfOnboardingDocumentFormData> =>
  (form: UseFormMethods<SelfOnboardingDocumentFormData>): TypedFormInputs<SelfOnboardingDocumentFormData> => {
    const { watch, setValue, getValues } = form;
    const { jobsiteInvitation, state, document, updateDocument, setIsContinueActionEnabled, localize } = args;
    const { jobsiteWorkerId } = state?.jobsiteWorker ?? {};

    const documentKey = document?.key;
    const prevDocKey = usePrevious(document?.key);

    const issueDate = watch('issueDate');
    useDidUpdateEffect(() => {
      const isTrainingConnectCard = getValues('isTrainingConnectCard') ?? false;

      // only make toggle smart if it not previously set to true
      if (!isTrainingConnectCard) {
        const issueMoment = moment(issueDate, 'MM/DD/YYYY', true);
        const newIsTrainingConnectCard = issueMoment.isSameOrAfter('01/01/2022');
        const isTrainingConnectCardChanged = newIsTrainingConnectCard !== isTrainingConnectCard;

        if (isTrainingConnectCardChanged) {
          setValue('isTrainingConnectCard', newIsTrainingConnectCard, { shouldDirty: true });
        }
      }
    }, [issueDate]);

    const acknowledgmentProgress = watch('acknowledgmentProgress');
    const acknowledgmentStatus = watch('acknowledgmentStatus');

    const isAcknowledgmentConfirmed = acknowledgmentStatus?.includes(AcknowledgmentStatus.Confirmed);
    useDidUpdateEffect(() => {
      if (isAcknowledgmentConfirmed && acknowledgmentProgress?.fraction !== 1) {
        setValue('acknowledgmentProgress', { ...acknowledgmentProgress, fraction: 1 });
      }
    }, [isAcknowledgmentConfirmed]);

    const isContinueActionEnabled =
      acknowledgmentProgress?.fraction === 1 && isAcknowledgmentConfirmed && !document.acknowledgmentDate;
    useDidUpdateEffect(() => {
      setIsContinueActionEnabled(isContinueActionEnabled);
    }, [isContinueActionEnabled]);

    const [upsertJobsiteWorkerDocument] = useUpsertJobsiteWorkerDocumentMutation();

    const saveAcknowledgmentProgress = async (): Promise<void> => {
      // make sure in the meanwhile the user hasn't changed the document by going back or skipping
      if (documentKey !== prevDocKey) return;
      const documentTypes = jobsiteInvitation.jobsiteContractor.jobsite.documentTypes.edges.map(({ node }) => node);
      const jobsiteWorkerDocumentTypeId = documentTypes.find((jdt) => jdt.workerDocumentType.key === documentKey).id;

      const { data } = await upsertJobsiteWorkerDocument({
        variables: {
          input: {
            jobsiteWorkerDocumentVersionId: document?.versionId,
            key: documentKey,
            jobsiteWorkerId,
            jobsiteWorkerDocumentTypeId,
            additionalFieldValues: ensureNonEmptyItems<AdditionalFieldValueInput>([
              {
                key: camelToKebabCase('acknowledgmentProgressFraction'),
                value: acknowledgmentProgress.fraction.toString(),
                type: 'string',
              },
              acknowledgmentProgress.value && {
                key: camelToKebabCase('acknowledgmentProgressValue'),
                value: acknowledgmentProgress.value.toString(),
                type: 'string',
              },
            ]),
          },
        },
      });
      const { jobsiteWorkerDocumentVersionId } = data.upsertJobsiteWorkerDocument;
      updateDocument({ ...document, acknowledgmentProgress, versionId: jobsiteWorkerDocumentVersionId });
    };

    const acknowledgmentProgressValue = acknowledgmentProgress?.value ?? 0;
    const documentAcknowledgmentProgressValue = document?.acknowledgmentProgress?.value ?? 0;
    const shouldSaveAcknowledgmentProgress =
      documentKey === prevDocKey && // make sure the user hasn't changed the document by going back or skipping
      (acknowledgmentProgressValue - documentAcknowledgmentProgressValue >= ACKNOWLEDGMENT_PROGRESS_SAVE_INTERVAL ||
        // save when watching is completed
        (acknowledgmentProgress?.fraction === 1 && document?.acknowledgmentProgress?.fraction !== 1));

    useDidUpdateEffect(() => {
      if (shouldSaveAcknowledgmentProgress) {
        saveAcknowledgmentProgress();
      }
    }, [shouldSaveAcknowledgmentProgress]);

    const [extractIdDataFromFile] = useExtractIdDataFromFileMutation({ fetchPolicy: 'no-cache' });
    const [loading, setLoading] = React.useState(false);

    const onBeforeFrontFileChange = React.useCallback(
      async (frontFile: InputFile): Promise<InputFile> => {
        if (!(frontFile instanceof File)) return undefined;

        const documentTypes = jobsiteInvitation.jobsiteContractor.jobsite.documentTypes.edges.map(({ node }) => node);
        const jobsiteWorkerDocumentTypeId = documentTypes.find((jdt) => jdt.workerDocumentType.key === documentKey).id;

        const frontFileData = await tryFileCompression(frontFile);
        try {
          setLoading(true);
          const { data } = await upsertJobsiteWorkerDocument({
            variables: {
              input: {
                jobsiteWorkerDocumentVersionId: document?.versionId,
                key: documentKey,
                jobsiteWorkerId,
                jobsiteWorkerDocumentTypeId,
                files: [
                  {
                    changeType: document.frontFile ? ChangeType.Updated : ChangeType.Created,
                    fileId: (document.frontFile as ServerFile)?.fileId,
                    fileInput: { uploadData: frontFileData, isPublic: false },
                  },
                ],
              },
            },
          });
          const { jobsiteWorkerDocumentVersionId, files } = data.upsertJobsiteWorkerDocument;
          const [firstFile] = files;
          updateDocument({ ...document, frontFile: firstFile, versionId: jobsiteWorkerDocumentVersionId });

          const extractionType = getExtractionProcessorType(documentKey);
          if (extractionType) {
            const extractedResult = await extractIdDataFromFile({
              variables: { extractionInput: { fileId: firstFile.fileId, extractionType } },
            });
            const { extractedData } = extractedResult.data.extractIdDataFromFile;
            const docUpdate = getDocUpdateFromExtractedData(extractedData);
            Object.entries(docUpdate ?? {}).forEach(([key, value]) => {
              setValue(key, value, { shouldDirty: true, shouldValidate: true });
            });
          }

          return firstFile;
        } catch (error) {
          AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
          return undefined;
        } finally {
          setLoading(false);
        }
      },
      [document, jobsiteWorkerId, jobsiteInvitation, upsertJobsiteWorkerDocument, setValue, setLoading],
    );

    return React.useMemo(() => {
      return getFormInputs({ document, onBeforeFrontFileChange, acknowledgmentProgress, localize, loading });
    }, [document, onBeforeFrontFileChange, acknowledgmentProgress, localize, loading]);
  };

export const getDefaultValues = (document: SelfOnboardingDocumentFormData): SelfOnboardingDocumentFormData => {
  const {
    key,
    acknowledgmentProgress,
    acknowledgmentStatus,
    frontFile,
    backFile,
    type,
    stateIssued,
    number,
    issueDate,
    expirationDate,
    isTrainingConnectCard,
  } = document ?? {};

  return {
    key,
    acknowledgmentProgress: acknowledgmentProgress ?? null,
    acknowledgmentStatus: acknowledgmentStatus ?? [],
    frontFile: frontFile ?? null,
    backFile: backFile ?? null,
    type: type ?? null,
    stateIssued: stateIssued ?? null,
    number: number ?? '',
    issueDate: issueDate ?? '',
    expirationDate: expirationDate ?? '',
    isTrainingConnectCard: isTrainingConnectCard ?? false,
  };
};

export type AdditionalFieldKey = keyof Pick<
  SelfOnboardingDocumentFormData & {
    acknowledgmentProgressFraction: AcknowledgmentProgress['fraction'];
    acknowledgmentProgressValue: AcknowledgmentProgress['value'];
  },
  | 'type'
  | 'stateIssued'
  | 'number'
  | 'issueDate'
  | 'expirationDate'
  | 'isTrainingConnectCard'
  | 'acknowledgmentProgressFraction'
  | 'acknowledgmentProgressValue'
  | 'acknowledgmentStatus'
  | 'acknowledgmentDate'
>;

export const additionalFieldKeys: AdditionalFieldKey[] = [
  'type',
  'stateIssued',
  'number',
  'issueDate',
  'expirationDate',
  'isTrainingConnectCard',
  'acknowledgmentProgressFraction',
  'acknowledgmentProgressValue',
  'acknowledgmentStatus',
  'acknowledgmentDate',
];

type AdditionalFieldValues =
  SelfOnboardingWorker['documents']['edges'][0]['node']['latestVersion']['additionalFieldValues'];

export const getValuesFromAdditionalFields = (
  documentKey: DocumentKey,
  additionalFieldValues: AdditionalFieldValues,
): SelfOnboardingDocumentFormData => {
  const getFieldValue = (fieldKey: AdditionalFieldKey): string => {
    return additionalFieldValues?.find((f) => f.key === camelToKebabCase(fieldKey))?.value;
  };

  const { typeOptions } = getDocumentInputsConfig(documentKey);

  const acknowledgmentStatus = getFieldValue('acknowledgmentStatus');
  const acknowledgmentDate = getFieldValue('acknowledgmentDate');
  const acknowledgmentProgressFraction = getFieldValue('acknowledgmentProgressFraction');
  const acknowledgmentProgressValue = getFieldValue('acknowledgmentProgressValue');

  return {
    acknowledgmentProgress: {
      fraction: acknowledgmentProgressFraction && Number(acknowledgmentProgressFraction),
      value: acknowledgmentProgressValue && Number(acknowledgmentProgressValue),
    },
    acknowledgmentStatus: ensureNonEmptyItems([ensureStringEnumValue(AcknowledgmentStatus, acknowledgmentStatus)]),
    acknowledgmentDate: acknowledgmentDate && new Date(acknowledgmentDate),
    type: typeOptions.find((opt) => opt.value === getFieldValue('type')),
    stateIssued: statesOptions.find((opt) => opt.value === getFieldValue('stateIssued')),
    number: getFieldValue('number'),
    issueDate: getPrettyFormattedUtcDate(getFieldValue('issueDate')),
    expirationDate: getPrettyFormattedUtcDate(getFieldValue('expirationDate')),
    isTrainingConnectCard: getFieldValue('isTrainingConnectCard') === 'true',
  };
};

/** This function maps the server additionalFields to local document field */
const getDocumentFieldName = (field: AdditionalFieldKey): keyof SelfOnboardingDocumentFormData => {
  switch (field) {
    case 'acknowledgmentProgressFraction':
    case 'acknowledgmentProgressValue':
      return 'acknowledgmentProgress';
    default:
      return field;
  }
};

export const getAcknowledgmentFile = async ({
  user,
  acknowledgmentFile,
  jobsiteInvitation,
}: {
  user: SelfOnboardingUser | { identity?: Pick<UserIdentity, 'firstName' | 'lastName'> };
  jobsiteInvitation: JobsiteInvitation;
  acknowledgmentFile: AcknowledgmentFile;
}): Promise<File> => {
  const { jobsite } = jobsiteInvitation.jobsiteContractor;
  const ipAddress = await getLocalIp();
  const workerName = getUserFullName(user);

  const res = await fetch(acknowledgmentFile.downloadUrl);
  const html = await res.text();

  const header = renderToStaticMarkup(<AcknowledgmentDocumentHeader jobsiteName={jobsite.name} />);
  const footer = renderToStaticMarkup(
    <AcknowledgmentDocumentFooter
      workerName={workerName}
      acknowledgmentDate={moment().format('dddd, MMMM Do YYYY')}
      ipAddress={ipAddress}
    />,
  );
  return new File([`${header}${html}${footer}`], 'acknowledgmentFile.html', { type: 'text/html' });
};

export const getUpdateInput = async ({
  user,
  document,
  jobsiteInvitation,
  jobsiteWorkerId,
  data,
  dirtyFields,
}: {
  user: SelfOnboardingUser;
  document: SelfOnboardingDocumentFormData;
  jobsiteInvitation: JobsiteInvitation;
  jobsiteWorkerId: string;
  data: SelfOnboardingDocumentFormData;
  dirtyFields: DeepMap<SelfOnboardingDocumentFormData, true>;
}): Promise<JobsiteWorkerDocumentUpsertInput> => {
  const { jobsite } = jobsiteInvitation.jobsiteContractor;
  const documentTypes = jobsite.documentTypes.edges.map(({ node }) => node);
  const documentKey = document.key;
  const jobsiteWorkerDocumentTypeId = documentTypes.find((dt) => dt.workerDocumentType.key === documentKey).id;

  const getUpdateInputValue = getUpdateInputValueFunction(data, dirtyFields);

  const getFieldValue = (field: AdditionalFieldKey): string => {
    const documentFieldName = getDocumentFieldName(field);
    const value = getUpdateInputValue(documentFieldName);
    if (value == null) {
      return value as null | undefined;
    }
    if (field.endsWith('Date')) {
      return moment(value as MomentInput, momentFormatter).toISOString();
    }
    if (field === 'acknowledgmentStatus' && Array.isArray(value)) {
      return value[0];
    }
    if (field === 'acknowledgmentProgressFraction') {
      return (value as AcknowledgmentProgress).fraction.toString();
    }
    if (field === 'acknowledgmentProgressValue') {
      return (value as AcknowledgmentProgress).value.toString();
    }
    return value.toString();
  };

  const getFileInput = async (field: 'frontFile' | 'backFile'): Promise<FileChangeInput> => {
    const value = getUpdateInputValue(field);
    if (value === undefined) return undefined;
    if (value === null) {
      return {
        changeType: ChangeType.Removed,
        fileId: (document[field] as ServerFile).fileId,
      };
    }
    const fileData = await tryFileCompression(value as File);
    return {
      changeType: document[field] ? ChangeType.Updated : ChangeType.Created,
      fileId: (document[field] as ServerFile)?.fileId,
      fileInput: { uploadData: fileData, isPublic: false },
    };
  };

  const getAcknowledgmentFileInput = async (): Promise<FileChangeInput> => {
    if (document.key !== DocumentKey.JobsiteSafetyDocument) return undefined;
    const { acknowledgmentFile } = document;
    const fileData = await getAcknowledgmentFile({ acknowledgmentFile, jobsiteInvitation, user });
    return {
      changeType: document.frontFile ? ChangeType.Updated : ChangeType.Created,
      fileId: (document.frontFile as ServerFile)?.fileId,
      fileInput: { uploadData: fileData, isPublic: false },
    };
  };

  const fieldsToSave = (Object.keys(data) as AdditionalFieldKey[]).filter((key) => additionalFieldKeys.includes(key));

  const additionalFieldValues = fieldsToSave
    .map((fieldName) => {
      return {
        key: camelToKebabCase(fieldName),
        value: getFieldValue(fieldName),
        type: 'string',
      };
    })
    .filter(({ value }) => value !== undefined);

  if (
    dirtyFields.acknowledgmentStatus &&
    data.acknowledgmentStatus?.includes(AcknowledgmentStatus.Confirmed) &&
    !document.acknowledgmentDate // make sure it's not already been confirmed
  ) {
    additionalFieldValues.push(
      {
        key: camelToKebabCase('acknowledgmentDate'),
        value: moment().format(),
        type: 'date',
      },
      {
        key: camelToKebabCase('acknowledgmentIpAddress'),
        value: await getLocalIp(),
        type: 'string',
      },
    );
  }

  // const frontFileInput = getFileInput('frontFile'); // this is handled when the file is added in Dropzone
  const backFileInput = await getFileInput('backFile');
  const acknowledgmentFileInput = await getAcknowledgmentFileInput();
  const files = [backFileInput, acknowledgmentFileInput].filter(Boolean);

  const changes = ensureNonUndefinedFields<
    Required<Pick<JobsiteWorkerDocumentUpsertInput, 'files' | 'additionalFieldValues'>>
  >({
    files: files.length ? files : undefined,
    additionalFieldValues: additionalFieldValues.length ? additionalFieldValues : undefined,
  });

  return isNotEmpty(changes)
    ? {
        jobsiteWorkerDocumentVersionId: document.versionId,
        jobsiteWorkerId,
        jobsiteWorkerDocumentTypeId,
        key: documentKey,
        ...changes,
      }
    : null;
};
